import { ETableColumnSort } from '@/components/CustomTableLayout/constants';
import { EFileActionMode, EJsonSchemaType } from '@/constants';
import { EModality, EOrderType } from '@/contexts/orderingContext/domain/constants';
import { TCustomizationFields, TJsonSchemaGeneric } from '@/types';

export enum ECustomAttributeType {
  string = 'string',
  dictionary = 'dictionary',
  strictDictionary = 'strict_dictionary',
  boolean = 'boolean',
  integer = 'integer',
  date = 'date',
  file = 'file',
}

export enum ECustomAttributeFormat {
  radio = 'radio',
}

export enum EEntityType {
  order = 'OrderingContext::Order',
  freightRequest = 'OrderingContext::FreightRequest',
  executionRequest = 'FreightExecutionContext::ExecutionRequest',
  run = 'RunExecutionContext::Run',
  executorsLookup = 'ExecutorsLookup',
  digitalQueueBooking = 'ContractorsContext::DigitalQueue::Booking',
  shipment = 'OrderingContext::Shipment',
  combinedOrder = 'OrderingContext::CombinedOrder',
}

export enum EScopeName {
  forBookingWithoutTarget = 'for_booking_without_target',
  forBookingWithShipment = 'for_booking_with_shipment',
}

export type TDictionaryItem = {
  id: string,
  description?: string,
  value: string,
};

export type TCustomAttributeDictionary = {
  id: string,
  name: string,
  items: TDictionaryItem[],
};

export type TModalitySetting = {
  modality: EModality,
  orderType: EOrderType,
  position?: number,
  required?: boolean,
};

export type TCustomAttributeFilterOption = {
  value: string | boolean,
  label: string,
};

export type TCustomAttribute = {
  id: string,
  name: string,
  entityType: EEntityType,
  type: ECustomAttributeType,
  readingOnly: boolean,
  slug: string,
  position: number,
  dictionary?: TCustomAttributeDictionary,
  options: {
    isRequired: boolean,
    format?: ECustomAttributeFormat,
    defaultValue?: string | number,
    modalitySettings: TModalitySetting[],
  },
};

export type TCustomAttributesResponse = TCustomAttribute[];

export type TCustomReference = {
  id: string,
  name: string,
  fieldsSchema: Record<string, TJsonSchemaGeneric<EJsonSchemaType>>,
};

export type TCustomReferencesResponse = TCustomReference[];

export type TCustomReferenceRow = {
  id: string,
} & TCustomizationFields;

export type TCustomReferenceRowsResponse = TCustomReferenceRow[];

export type TCustomReferenceRowsRequest = {
  customReferenceId: string,
  filterId?: string | null,
  sort?: ETableColumnSort | '' | null,
  sortBy?: string | null,
};

export type TImportCustomReferenceRowsRequest = {
  customReferenceId: string,
  mode: EFileActionMode,
};

export type TReferenceAsSelectOption = {
  key: string,
  value: string,
  additionalValue?: string,
};

export type TReferenceAsSelectOptionsResponse = TReferenceAsSelectOption[];
