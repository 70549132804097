export enum ENotificationType {
  partnershipsContextNotifyAboutInvitation = 'PartnershipsContext::NotifyAboutInvitation',
  partnershipsContextCustomersNotifyAboutNewPartnership = 'PartnershipsContext::Customers::NotifyAboutNewPartnership',
  partnershipsContextExecutorsNotifyAboutNewPartnership = 'PartnershipsContext::Executors::NotifyAboutNewPartnership',
  orderingContextCustomersNotifyAboutCompletedOrder = 'OrderingContext::Customers::NotifyAboutCompletedOrder',
  freightExecutionContextCustomersNotifyAboutCanceledByExecutorRequest = 'FreightExecutionContext::Customers::NotifyAboutCanceledByExecutorRequest',
  freightExecutionContextCustomersNotifyAboutCompletedExecutionRequest = 'FreightExecutionContext::Customers::NotifyAboutCompletedExecutionRequest',
  freightExecutionContextCustomersNotifyAboutNewRequest = 'FreightExecutionContext::Customers::NotifyAboutNewRequest',
  freightExecutionContextExecutorsNotifyAboutCanceledByCustomerRequest = 'FreightExecutionContext::Executors::NotifyAboutCanceledByCustomerRequest',
  freightExecutionContextExecutorsNotifyAboutChangedAdditionalInformationOnTransitPoint = 'FreightExecutionContext::Executors::NotifyAboutChangedAdditionalInformationOnTransitPoint',
  freightExecutionContextExecutorsNotifyAboutChangedAddressOnTransitPoint = 'FreightExecutionContext::Executors::NotifyAboutChangedAddressOnTransitPoint',
  freightExecutionContextExecutorsNotifyAboutChangedCargoInformation = 'FreightExecutionContext::Executors::NotifyAboutChangedCargoInformation',
  freightExecutionContextExecutorsNotifyAboutChangedDriverRequirements = 'FreightExecutionContext::Executors::NotifyAboutChangedDriverRequirements',
  freightExecutionContextExecutorsNotifyAboutChangedExecutionRequestRoute = 'FreightExecutionContext::Executors::NotifyAboutChangedExecutionRequestRoute',
  freightExecutionContextExecutorsNotifyAboutChangedExpectedArrivalTimeOnTransitPoint = 'FreightExecutionContext::Executors::NotifyAboutChangedExpectedArrivalTimeOnTransitPoint',
  freightExecutionContextExecutorsNotifyAboutChangedInformationOnTransitPoint = 'FreightExecutionContext::Executors::NotifyAboutChangedInformationOnTransitPoint',
  freightExecutionContextExecutorsNotifyAboutChangedVehicleRequirements = 'FreightExecutionContext::Executors::NotifyAboutChangedVehicleRequirements',
  freightExecutionContextExecutorsNotifyAboutChangedPublicComment = 'FreightExecutionContext::Executors::NotifyAboutChangedPublicComment',
  freightExecutionContextExecutorsNotifyAboutChangedPrice = 'FreightExecutionContext::Executors::NotifyAboutChangedPrice',
  freightExecutionContextExecutorsNotifyAboutChangedExtraServicePrice = 'FreightExecutionContext::Executors::NotifyAboutChangedExtraServicePrice',
  freightExecutionContextExecutorsNotifyAboutNewExecutionRequest = 'FreightExecutionContext::Executors::NotifyAboutNewExecutionRequest',
  freightExecutionContextExecutorsNotifyAboutChangedPriceDueToDistanceChange = 'FreightExecutionContext::Executors::NotifyAboutChangedPriceDueToDistanceChange',
  freightExecutionContextApprovalsNotifyAboutPending = 'FreightExecutionContext::Approvals::NotifyAboutPending',
  freightExecutionContextApprovalsNotifyAboutApproval = 'FreightExecutionContext::Approvals::NotifyAboutApproval',
  freightExecutionContextApprovalsNotifyAboutRevoking = 'FreightExecutionContext::Approvals::NotifyAboutRevoking',
  freightExecutionContextApprovalsNotifyAboutMarkedAsInRevision = 'FreightExecutionContext::Approvals::NotifyAboutMarkedAsInRevision',
  freightExecutionContextApprovalsNotifyAboutRejection = 'FreightExecutionContext::Approvals::NotifyAboutRejection',
  runExecutionContextCustomersNotifyAboutPlannedRun = 'RunExecutionContext::Customers::NotifyAboutPlannedRun',
  runExecutionContextCustomersNotifyAboutReplacedTransport = 'RunExecutionContext::Customers::NotifyAboutReplacedTransport',
  runExecutionContextCustomersNotifyAboutNewRunIncident = 'RunExecutionContext::Customers::NotifyAboutNewRunIncident',
  runExecutionContextCustomersNotifyAboutResolvedRunIncident = 'RunExecutionContext::Customers::NotifyAboutResolvedRunIncident',
  runExecutionContextCustomersNotifyAboutCompletedRun = 'RunExecutionContext::Customers::NotifyAboutCompletedRun',
  runExecutionContextCustomersNotifyAboutCompletionOfCanceledRun = 'RunExecutionContext::Customers::NotifyAboutCompletionOfCanceledRun',
  runExecutionContextCustomersNotifyAboutCancelationOfCompletedRun = 'RunExecutionContext::Customers::NotifyAboutCancelationOfCompletedRun',
  runExecutionContextCustomersNotifyAboutCanceledRun = 'RunExecutionContext::Customers::NotifyAboutCanceledRun',
  runExecutionContextExecutorsNotifyAboutPlannedRun = 'RunExecutionContext::Executors::NotifyAboutPlannedRun',
  runExecutionContextExecutorsNotifyAboutReplacedTransport = 'RunExecutionContext::Executors::NotifyAboutReplacedTransport',
  runExecutionContextExecutorsNotifyAboutRunOnDocumentsPreparation = 'RunExecutionContext::Executors::NotifyAboutRunOnDocumentsPreparation',
  runExecutionContextExecutorsNotifyAboutFinishedRunExecution = 'RunExecutionContext::Executors::NotifyAboutFinishedRunExecution',
  runExecutionContextExecutorsNotifyAboutNewRunIncident = 'RunExecutionContext::Executors::NotifyAboutNewRunIncident',
  runExecutionContextExecutorsNotifyAboutResolvedRunIncident = 'RunExecutionContext::Executors::NotifyAboutResolvedRunIncident',
  runExecutionContextExecutorsNotifyAboutCompletedRun = 'RunExecutionContext::Executors::NotifyAboutCompletedRun',
  targetOffersContextCustomersNotifyAboutAcceptedTargetOffer = 'TargetOffersContext::Customers::NotifyAboutAcceptedTargetOffer',
  targetOffersContextCustomersNotifyAboutRejectedTargetOffer = 'TargetOffersContext::Customers::NotifyAboutRejectedTargetOffer',
  targetOffersContextCustomersNotifyAboutExpiredTargetOffer = 'TargetOffersContext::Customers::NotifyAboutExpiredTargetOffer',
  targetOffersContextExecutorsNotifyAboutNewTargetOffer = 'TargetOffersContext::Executors::NotifyAboutNewTargetOffer',
  spotOffersContextCustomersNotifyAboutAcceptedSpotOffer = 'SpotOffersContext::Customers::NotifyAboutAcceptedSpotOffer',
  spotOffersContextCustomersNotifyAboutExpiredSpotOffer = 'SpotOffersContext::Customers::NotifyAboutExpiredSpotOffer',
  spotOffersContextExecutorsNotifyAboutPublishedSpotOffer = 'SpotOffersContext::Executors::NotifyAboutPublishedSpotOffer',
  reductionsContextContextCustomersNotifyAboutExpiredReduction = 'ReductionsContext::Customers::NotifyAboutExpiredReduction',
  reductionsContextContextCustomersNotifyAboutPlacedBid = 'ReductionsContext::Customers::NotifyAboutPlacedBid',
  reductionsContextCustomersNotifyAboutRevokedBid = 'ReductionsContext::Customers::NotifyAboutRevokedBid',
  reductionsContextCustomersNotifyAboutExpiredBid = 'ReductionsContext::Customers::NotifyAboutExpiredBid',
  reductionsContextContextCustomersNotifyAboutFinishedByTimeReduction = 'ReductionsContext::Customers::NotifyAboutFinishedByTimeReduction',
  reductionsContextContextCustomersNotifyAboutFinishedByBuyNowReduction = 'ReductionsContext::Customers::NotifyAboutFinishedByBuyNowReduction',
  reductionsContextContextExecutorsNotifyAboutKnockedOutBid = 'ReductionsContext::Executors::NotifyAboutKnockedOutBid',
  reductionsContextContextExecutorsNotifyAboutReactivatedBid = 'ReductionsContext::Executors::NotifyAboutReactivatedBid',
  reductionsContextContextExecutorsNotifyAboutPublishedReduction = 'ReductionsContext::Executors::NotifyAboutPublishedReduction',
  reductionsContextContextExecutorsNotifyAboutManuallyFinishedReduction = 'ReductionsContext::Executors::NotifyAboutManuallyFinishedReduction',
  reductionsContextContextExecutorsNotifyAboutFinishedByTimeReduction = 'ReductionsContext::Executors::NotifyAboutFinishedByTimeReduction',
  reductionsContextContextExecutorsNotifyAboutFinishedByBuyNowReduction = 'ReductionsContext::Executors::NotifyAboutFinishedByBuyNowReduction',
  freeOfferingsContextExecutorsNotifyAboutFreeOfferingOrganized = 'FreeOfferingsContext::Executors::NotifyAboutFreeOfferingOrganized',
  executorsLookupContextCustomersNotifyAboutFailedStageStart = 'ExecutorsLookupContext::Customers::NotifyAboutFailedStageStart',
  executorsLookupContextCustomersNotifyAboutFailedAutomationStepExecution = 'ExecutorsLookupContext::Customers::NotifyAboutFailedAutomationStepExecution',
  executorsLookupContextExecutorsNotifyAboutNewLookups = 'ExecutorsLookupContext::Executors::NotifyAboutNewLookups',
  tendersContextExecutorsNotifyAboutTenderStageStart = 'TendersContext::Executors::NotifyAboutTenderStageStart',
  tendersContextExecutorsNotifyAboutTenderStageCancel = 'TendersContext::Executors::NotifyAboutTenderStageCancel',
  tendersContextExecutorsNotifyAboutTenderStagePause = 'TendersContext::Executors::NotifyAboutTenderStagePause',
  tendersContextExecutorsNotifyAboutTenderMailings = 'TendersContext::Executors::NotifyAboutTenderMailings',
  tenderContextExecutorsNotifyAboutTenderStageResume = 'TendersContext::Executors::NotifyAboutTenderStageResume',
  tenderContextExecutorsNotifyAboutTenderStageDurationChange = 'TendersContext::Executors::NotifyAboutTenderStageDurationChange',
  billingContextExecutorsNotifyAboutTerminateSpecialOffer = 'BillingContext::Executors::NotifyAboutTerminateSpecialOffer',
}

export const enum ENotificationStatus {
  read = 'read',
  unread = 'unread',
}
