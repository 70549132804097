import { defineStore } from 'pinia';
import { ref } from 'vue';

import { EViewSettingsSections } from './constants';
import { TViewSettings, TViewSettingsStore } from './types';

const getEmptySort = () => ({
  sort: {
    sort: null,
    sortBy: null,
  },
});

export const useViewSettingsStore = defineStore(
  'TMS_VIEW_SETTINGS',
  (): TViewSettingsStore => {
    const executorsLookupsFeed = ref<TViewSettings>(getEmptySort());

    const contractorPersonalAccountCurrentRunsTable = ref<TViewSettings>(getEmptySort());

    const contractorPersonalAccountFinishedRunsTable = ref<TViewSettings>(getEmptySort());

    const contractorPersonalAccountPlannedRunsTable = ref<TViewSettings>(getEmptySort());

    const executionRequestsTable = ref<TViewSettings>(getEmptySort());

    const ordersFinalized = ref<TViewSettings>(getEmptySort());

    const ordersOnBidding = ref<TViewSettings>(getEmptySort());

    const ordersOnExecution = ref<TViewSettings>(getEmptySort());

    const ordersOnTargetOffering = ref<TViewSettings>(getEmptySort());

    const ordersPending = ref<TViewSettings>(getEmptySort());

    const combinedOrdersTable = ref<TViewSettings>(getEmptySort());

    const bookingsSlotFeeds = ref<TViewSettings>(getEmptySort());

    const setViewSetting = (section: EViewSettingsSections, data: TViewSettings) => {
      if (!section || !data) return;

      switch (section) {
        case EViewSettingsSections.executorsLookupsFeed: {
          executorsLookupsFeed.value = data;
          break;
        }
        case EViewSettingsSections.contractorPersonalAccountCurrentRunsTable: {
          contractorPersonalAccountCurrentRunsTable.value = data;
          break;
        }
        case EViewSettingsSections.contractorPersonalAccountFinishedRunsTable: {
          contractorPersonalAccountFinishedRunsTable.value = data;
          break;
        }
        case EViewSettingsSections.contractorPersonalAccountPlannedRunsTable: {
          contractorPersonalAccountPlannedRunsTable.value = data;
          break;
        }
        case EViewSettingsSections.executionRequestsTable: {
          executionRequestsTable.value = data;
          break;
        }
        case EViewSettingsSections.ordersFinalized: {
          ordersFinalized.value = data;
          break;
        }
        case EViewSettingsSections.ordersOnBidding: {
          ordersOnBidding.value = data;
          break;
        }
        case EViewSettingsSections.ordersOnExecution: {
          ordersOnExecution.value = data;
          break;
        }
        case EViewSettingsSections.ordersOnTargetOffering: {
          ordersOnTargetOffering.value = data;
          break;
        }
        case EViewSettingsSections.ordersPending: {
          ordersPending.value = data;
          break;
        }
        case EViewSettingsSections.combinedOrdersTable: {
          combinedOrdersTable.value = data;
          break;
        }
        case EViewSettingsSections.bookingsSlotFeed: {
          bookingsSlotFeeds.value = data;
          break;
        }
        default: break;
      }
    };

    const $reset = () => {
      executorsLookupsFeed.value = getEmptySort();
      contractorPersonalAccountCurrentRunsTable.value = getEmptySort();
      contractorPersonalAccountFinishedRunsTable.value = getEmptySort();
      contractorPersonalAccountPlannedRunsTable.value = getEmptySort();
      executionRequestsTable.value = getEmptySort();
      ordersFinalized.value = getEmptySort();
      ordersOnBidding.value = getEmptySort();
      ordersOnExecution.value = getEmptySort();
      ordersOnTargetOffering.value = getEmptySort();
      ordersPending.value = getEmptySort();
      combinedOrdersTable.value = getEmptySort();
      bookingsSlotFeeds.value = getEmptySort();
    };

    return {
      executorsLookupsFeed,
      contractorPersonalAccountCurrentRunsTable,
      contractorPersonalAccountFinishedRunsTable,
      contractorPersonalAccountPlannedRunsTable,
      executionRequestsTable,
      ordersFinalized,
      ordersOnBidding,
      ordersOnExecution,
      ordersOnTargetOffering,
      ordersPending,
      combinedOrdersTable,
      bookingsSlotFeeds,

      setViewSetting,
      $reset,
    };
  },
  { persist: true },
);
