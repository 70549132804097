import {
  Ref,
  computed,
  nextTick,
  ref,
  watch,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import {
  getCurrentDayJsDate,
  getDateByStartOfPart,
  getDaysFromDate,
  getMonthsFromDate,
  getYearsFromDate,
} from '@/utils/dateUtils';
import {
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  EMonth,
} from '@/ui/types/constants';
import { TDateAppFormat } from '@/types';

import DatePickerMonthFeed from '../components/DatePickerMonthFeed/index.vue';
import {
  TChangeDateStateArguments,
  TDateSections,
  THandleInputChangeArguments,
} from '../domain/types';
import { getMonthAndYearForFeed } from '../domain/getMonthAndYearForFeed';

type TUseInteractWithDatePicker = {
  selectedDateState: Ref<TDateSections>,
  minDate: Ref<TDateAppFormat | null>,
  maxDate: Ref<TDateAppFormat | null>,
  datePickerMonthFeedRef: Ref<InstanceType<typeof DatePickerMonthFeed> | null>,
  isDropdownVisible: Ref<boolean>,
  setDropdownFocus: () => void,
  handleDropdownClose: () => void,
  changeDateState: ({ newDateState, isDateFormatNeeded }: TChangeDateStateArguments) => void,
  prepareDateStateValue: () => string | null,
  emitDateValue: (dateStateValue: string | null) => void,
};

export const useInteractWithDatePicker = (
  {
    selectedDateState,
    minDate,
    maxDate,
    datePickerMonthFeedRef,
    isDropdownVisible,
    setDropdownFocus,
    handleDropdownClose,
    changeDateState,
    prepareDateStateValue,
    emitDateValue,
  }: TUseInteractWithDatePicker,
) => {
  const currentDate = getDateByStartOfPart(getCurrentDayJsDate(), 'day');

  const monthSelectValue = ref(getMonthsFromDate(currentDate) + 1);
  const yearSelectValue = ref(getYearsFromDate(currentDate));

  const yearsOptions = computed(() => {
    const maxYear = getYearsFromDate(maxDate.value || DEFAULT_MAX_DATE);
    const minYear = getYearsFromDate(minDate.value || DEFAULT_MIN_DATE);

    return Array.from({ length: maxYear - minYear + 1 }).map((_, index: number) => ({
      value: minYear + index,
      label: minYear + index,
    }));
  });

  const monthsOptions = computed(
    () => Object.keys(EMonth).filter((value: string) => Number.isNaN(Number(value))).map(
      (month: string, index: number) => ({
        value: index + 1,
        label: tt(`shared.months.${month as keyof typeof EMonth}`),
      })),
  );

  const handleCurrentDateSet = () => {
    const day = getDaysFromDate(currentDate);
    // Увеличиваем на 1, так как функция возвращает месяц в диапазоне от 0 до 11
    const month = getMonthsFromDate(currentDate) + 1;
    const year = getYearsFromDate(currentDate);

    const newDateState: TDateSections = {
      day: `${day}`,
      month: `${month}`,
      year: `${year}`,
    };

    changeDateState({ newDateState });

    handleSelectedMonthChange(month, year);

    datePickerMonthFeedRef.value?.changeMonthFeedState({
      month,
      year,
    });
  };

  const handleSelectedMonthChange = (month: EMonth, year: number) => {
    monthSelectValue.value = month;
    yearSelectValue.value = year;
  };

  const handleMonthSelect = (month: EMonth) => {
    setDropdownFocus();

    monthSelectValue.value = month;

    datePickerMonthFeedRef.value?.changeMonthFeedState({
      month,
      year: yearSelectValue.value,
    });
  };

  const handleYearSelect = (year: number) => {
    setDropdownFocus();

    yearSelectValue.value = year;

    datePickerMonthFeedRef.value?.changeMonthFeedState({
      month: monthSelectValue.value,
      year,
    });
  };

  const handleDaySelect = (date: TDateSections) => {
    changeDateState({ newDateState: date });

    handleDropdownClose();
  };

  const handleInputChange = ({
    newDateState,
    isScrollFeedInstant = false,
  }: THandleInputChangeArguments) => {
    changeDateState({
      newDateState,
      isDateFormatNeeded: false,
    });

    const { feedMonth, feedYear } = getMonthAndYearForFeed(newDateState);

    // Если не удалось получить месяц/год, не скроллим ленту и не изменяем состояние селектов
    if (!feedMonth || !feedYear) return;

    handleSelectedMonthChange(feedMonth, feedYear);

    datePickerMonthFeedRef.value?.changeMonthFeedState({
      month: feedMonth,
      year: feedYear,
      isScrollFeedInstant,
    });
  };

  watch(isDropdownVisible, () => {
    // Дропдаун открыт - нужно проинициализировать ленту
    if (isDropdownVisible.value) {
      const { feedMonth, feedYear } = getMonthAndYearForFeed(selectedDateState.value);

      if (feedMonth && feedYear) {
        monthSelectValue.value = feedMonth;
        yearSelectValue.value = feedYear;
      } else {
        monthSelectValue.value = getMonthsFromDate(currentDate) + 1;
        yearSelectValue.value = getYearsFromDate(currentDate);
      }

      // дожидаемся когда будет доступен компонент dropdown с лентой месяцев
      nextTick(() => {
        datePickerMonthFeedRef.value?.setMonthFeedInitialState();
      });
      // Дропдаун закрыт
    } else {
      const dateStateValue = prepareDateStateValue();

      emitDateValue(dateStateValue);
    }
  });

  return {
    yearsOptions,
    monthsOptions,
    monthSelectValue,
    yearSelectValue,

    handleCurrentDateSet,
    handleSelectedMonthChange,
    handleDaySelect,
    handleMonthSelect,
    handleYearSelect,
    handleInputChange,
  };
};
