import {
  Ref,
  computed,
  ref,
  watch,
} from 'vue';

import { useFormHelpers } from '@/composables/useFormHelpers';
import tt from '@/i18n/utils/translateText';
import { TBankAccountResponse } from '@/contexts/accountingContext/domain/types';
import { DATE_FORMAT, DEFAULT_EMAIL_RULE } from '@/constants';
import { generatePhoneRule } from '@/domains/generatePhoneRule';
import useStore from '@/store/useStore';
import { TDomainError, TSubscription } from '@/types';
import { formatDate, getCurrentDayJsDate } from '@/utils/dateUtils';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';

import { TBillingCarrierForm } from '../domain/types';

export const useInteractWithForm = (
  bankAccountInfo: Ref<TBankAccountResponse | null>,
) => {
  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const activeSubscription = computed(
    () => currentTenantInfo.value?.subscriptions?.find(
      (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null,
  );

  const formData = ref<TBillingCarrierForm>({
    subscriptionAt: formatDate(getCurrentDayJsDate(), { outputFormat: DATE_FORMAT.fullDate }),
    bankIdentificationCode: '',
    organizationAddress: currentTenantInfo.value?.legalEntity?.legalAddress || '',
    organizationPhone: currentTenantInfo.value?.legalEntity?.phone || '',
    bankAccountNumber: '',
    edmAvailable: currentTenantInfo.value?.legalEntity?.edmAvailable || false,
    publicOfferAccept: false,
    invoicingEmail: currentTenantInfo.value?.legalEntity?.invoicingEmail || '',
  });

  const isPublicOfferAccept = computed(() => formData.value.publicOfferAccept);

  const isSpecialPlansForBillingAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.specialPlansForBilling),
  );

  // Блокируем выбор даты (при смене тарифа), если для активной подписки действуют спец условия (https://jira.trucker.group/browse/TMS-8318)
  const isDatePickerDisabled = computed(
    () => isSpecialPlansForBillingAvailable.value && !!activeSubscription.value?.specialOffer,
  );

  const isEmailConfirmed = ref(false);

  const rules = computed(() => ({
    subscriptionAt: [{
      required: true,
      message: tt('shared.rule.commonRequired'),
    }],
    bankIdentificationCode: [{
      required: true,
      message: tt('shared.rule.commonRequired'),
    }],
    organizationAddress: [{
      required: true,
      message: tt('shared.rule.commonRequired'),
    }],
    organizationPhone: [
      generatePhoneRule(formData.value.organizationPhone),
    ],
    bankAccountNumber: [{
      required: true,
      message: tt('shared.rule.commonRequired'),
    }, {
      len: 20,
      message: tt('shared.rule.commonLength', { param: { count: 20 } }),
    }],
    invoicingEmail: [DEFAULT_EMAIL_RULE, {
      required: true,
      message: tt('shared.rule.emailRequired'),
    }],
  }));

  const handleEmailConfirmed = () => {
    isEmailConfirmed.value = true;
  };

  const {
    validateInfos,

    setResponseErrors: onError,
    isFieldInvalid,
    onControlChange,
    validate,
  } = useFormHelpers(
    formData.value,
    rules,
    { validateOnRuleChange: false },
  );

  const setValue = (field: string, value: string | number | boolean) => {
    formData.value[field] = value;

    handleFieldChange(field);
  };

  const handleFieldChange = (field: string) => {
    onControlChange({ name: field });
  };

  const setResponseErrors = (error: TDomainError) => {
    onError(error);

    validate().catch(() => {});
  };

  const handleChangeEmail = () => {
    isEmailConfirmed.value = false;
    formData.value.invoicingEmail = '';
  };

  const onSubmit = () => validate()
    .then(() => Promise.resolve(formData.value))
    .catch(() => Promise.reject());

  watch(() => bankAccountInfo.value, (info: TBankAccountResponse | null) => {
    if (info) {
      formData.value.bankIdentificationCode = info?.bik;
      formData.value.bankAccountNumber = info?.checkingAccount;
    }
  });

  return {
    formData,
    isPublicOfferAccept,
    isEmailConfirmed,
    validateInfos,
    isDatePickerDisabled,

    isFieldInvalid,
    setValue,
    handleFieldChange,
    handleEmailConfirmed,
    handleChangeEmail,
    onSubmit,
    setResponseErrors,
  };
};
