import tt from '@/i18n/utils/translateText';

export enum EVehicleType {
  donkey = 'donkey',
  trailer = 'trailer',
  semitrailer = 'semitrailer',
  truck = 'truck',
}

export enum ETransportResourceVehicleType {
  truck = 'truck',
  donkey = 'donkey',
  mainTrailer = 'mainTrailer',
  secondaryTrailer = 'secondaryTrailer',
}

export const VEHICLE_ROUTE_NAME = {
  vehicleView: 'vehicleView',
  vehicleInformationView: 'vehicleInformationView',
};

export const EMPLOYEE_ROUTE_NAME = {
  employees: 'employees',
  employeesAll: 'employeesAll',
  employeesResponsible: 'employeesResponsible',
  employeeView: 'employeeView',
  employeeInformationView: 'employeeInformationView',
  employeeNotificationSettingsView: 'employeeNotificationSettingsView',
};

export enum EVehicleUnitType {
  trailer = 'ResourcesContext::Trailer',
  donkey = 'ResourcesContext::Donkey',
  truck = 'ResourcesContext::Truck',
}

export const VEHICLE_UNIT_BY_TYPE = {
  [EVehicleType.semitrailer]: EVehicleUnitType.trailer,
  [EVehicleType.trailer]: EVehicleUnitType.trailer,
  [EVehicleType.donkey]: EVehicleUnitType.donkey,
  [EVehicleType.truck]: EVehicleUnitType.truck,
};

export const VEHICLE_TYPE_BY_VEHICLE_UNIT_TYPE = {
  [EVehicleUnitType.trailer]: EVehicleType.trailer,
  [EVehicleUnitType.donkey]: EVehicleType.donkey,
  [EVehicleUnitType.truck]: EVehicleType.truck,
};

export enum EmployeeAccountType {
  corporate = 'corporate',
  personal = 'personal',
  none = 'none',
}

export enum EmployeeRole {
  logist = 'logist',
  manager = 'manager',
}

export enum EmployeeInvitationStatus {
  active = 'active',
  revoked = 'revoked',
  accepted = 'accepted',
  expired = 'expired',
}

export enum ELoadingTypes {
  rear = 'rear',
  side = 'side',
  bothSide = 'both_side',
  top = 'top',
}

export const LOADING_TYPES_OPTIONS = [
  {
    id: ELoadingTypes.rear,
    name: tt('resourcesControl.shared.loadingTypes.rear'),
  },
  {
    id: ELoadingTypes.side,
    name: tt('resourcesControl.shared.loadingTypes.side'),
  },
  {
    id: ELoadingTypes.bothSide,
    name: tt('resourcesControl.shared.loadingTypes.bothSide'),
  },
  {
    id: ELoadingTypes.top,
    name: tt('resourcesControl.shared.loadingTypes.top'),
  },
];

export const LOADING_TYPES_TRANSLATE = {
  [ELoadingTypes.rear]: tt('resourcesControl.shared.loadingTypes.rear'),
  [ELoadingTypes.side]: tt('resourcesControl.shared.loadingTypes.side'),
  [ELoadingTypes.bothSide]: tt('resourcesControl.shared.loadingTypes.bothSide'),
  [ELoadingTypes.top]: tt('resourcesControl.shared.loadingTypes.top'),
};

export enum EEmailForNotificationSource {
  user = 'user',
  employee = 'employee',
}

export enum EOwnershipType {
  own = 'own',
  spouses = 'spouses',
  rent = 'rent',
  leasing = 'leasing',
  freeUsage = 'free_usage',
}

export const OWNERSHIP_TYPES_OPTIONS = [
  {
    id: EOwnershipType.own,
    name: tt('shared.own'),
  },
  {
    id: EOwnershipType.spouses,
    name: tt('shared.spouses'),
  },
  {
    id: EOwnershipType.rent,
    name: tt('shared.rent'),
  },
  {
    id: EOwnershipType.leasing,
    name: tt('shared.leasing'),
  },
  {
    id: EOwnershipType.freeUsage,
    name: tt('shared.freeUsage'),
  },
];

export enum EOrigin {
  russia = 'russia',
  other = 'other',
}

export enum EOwnershipRole {
  forwarder = 'forwarder',
  executor = 'executor',
}

export const REFRESH_VEHICLES_TABLE_EVENT = 'refreshVehiclesTable';

export const UPDATE_EMPLOYEE_IN_EMPLOYEES_TABLE_EVENT = 'updateEmployeeInEmployeesTable';
