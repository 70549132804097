import { getCSSProperty } from './getCSSProperty';

type TSetLeftWithMaxWidthParams = {
  targetElementClientRectRight?: number,
  targetElementClientRectLeft?: number,
  targetElementClientRectWidth?: number,
  dropdownMatchSelectWidth: number,
  isFixedOnBottomLeft: boolean,
};

export const setLeftWithMaxWidth = ({
  isFixedOnBottomLeft,
  targetElementClientRectLeft,
  targetElementClientRectRight,
  targetElementClientRectWidth,
  dropdownMatchSelectWidth,
}: TSetLeftWithMaxWidthParams) => {
  let left = targetElementClientRectLeft ? getCSSProperty({
    key: 'left',
    value: targetElementClientRectLeft,
    unit: 'px',
  }) : '';

  const hasTargetElementClientRect = targetElementClientRectRight
    && targetElementClientRectLeft
    && targetElementClientRectWidth;

  if (hasTargetElementClientRect) {
    if (isFixedOnBottomLeft) {
      /** зафиксировать Dropdown у левого края Select'а */
      left = getCSSProperty({
        key: 'left',
        value: targetElementClientRectLeft,
        unit: 'px',
      });
    } else {
      const rightSpace = document.documentElement.clientWidth - targetElementClientRectLeft;
      if (
        rightSpace >= dropdownMatchSelectWidth
        /*
          проверка ниже нужна для маленьких по ширине экранов, когда целиком дропдаун не помещается по правую сторону от контрола,
          но рабочая область по правую сторону контрола всё еще больше, чем по левую
        */
        || rightSpace >= targetElementClientRectRight
      ) {
        /* если справа места больше, чем слева, Dropdown прижат к левому краю контрола */
        left = getCSSProperty({
          key: 'left',
          value: targetElementClientRectLeft,
          unit: 'px',
        });
      } else {
        /* Dropdown прижат к правому краю контрола */
        let leftPosition;
        const position = Math.abs(dropdownMatchSelectWidth - targetElementClientRectWidth);
        if (dropdownMatchSelectWidth > targetElementClientRectWidth) {
          leftPosition = targetElementClientRectLeft - position;
        } else {
          leftPosition = targetElementClientRectLeft + position;
        }
        left = leftPosition ? getCSSProperty({
          key: 'left',
          value: leftPosition,
          unit: 'px',
        }) : '';
      }
    }
  }

  return left;
};
