<template>
  <div
    ref="feedViewportRef"
    class="date-picker-month-feed"
    :style="{ overflow: isFeedViewportOverflowHidden ? 'hidden' : 'auto' }"
  >
    <div :style="{ height: `${maxMonthFeedHeight}px` }">
      <div
        ref="monthElementsWrapperRef"
        :style="{ transform: `translateY(${translateYOffset}px)` }"
      >
        <DatePickerMonth
          v-for="(month, index) in monthElements"
          :ref="el => setMonthElementsRefs(el, index)"
          :key="month.month + '-' + month.year"
          :month="month.month"
          :year="month.year"
          :selectedDate="selectedDate"
          :minDate="minDate"
          :maxDate="maxDate"
          :isMonthSelected="month.isMonthSelected"
          @select="$emit('select', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {
  ComponentPublicInstance,
  PropType,
  defineComponent,
  ref,
  toRefs,
} from 'vue';

import { EMonth } from '@/ui/types/constants';
import { TDateAppFormat } from '@/types';

import DatePickerMonth from './components/DatePickerMonth/index.vue';
import { useInteractWithMonthFeed } from './application/useInteractWithMonthFeed';

export default defineComponent({
  name: 'DatePickerMonthFeed',
  components: { DatePickerMonth },
  props: {
    selectedMonth: {
      type: Number as PropType<EMonth>,
      required: true,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
    selectedDate: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    minDate: {
      type: [Object, null] as PropType<TDateAppFormat | null>,
      default: null,
    },
    maxDate: {
      type: [Object, null] as PropType<TDateAppFormat | null>,
      default: null,
    },
  },
  emits: ['changeSelectedMonth', 'select'],
  setup(props, { emit, expose }) {
    const {
      selectedMonth,
      selectedYear,
    } = toRefs(props);

    const feedViewportRef = ref<HTMLElement | null>(null);
    const monthElementsWrapperRef = ref<HTMLElement | null>(null);
    const monthElementsRefs = ref<(InstanceType<typeof DatePickerMonth>[])>([]);

    const setMonthElementsRefs = (monthElement: Element | ComponentPublicInstance | null, index: number) => {
      if (!monthElement) return;

      monthElementsRefs.value[index] = monthElement as InstanceType<typeof DatePickerMonth>;
    };

    const {
      monthElements,
      translateYOffset,
      maxMonthFeedHeight,
      isFeedViewportOverflowHidden,

      setMonthFeedInitialState,
      changeMonthFeedState,
    } = useInteractWithMonthFeed({
      monthElementsRefs,
      feedViewportRef,
      monthElementsWrapperRef,
      selectedMonth,
      selectedYear,

      emit,
    });

    expose({
      changeMonthFeedState,
      setMonthFeedInitialState,
    });

    return {
      feedViewportRef,
      monthElementsWrapperRef,
      monthElementsRefs,
      monthElements,
      maxMonthFeedHeight,
      translateYOffset,
      isFeedViewportOverflowHidden,

      setMonthElementsRefs,
      setMonthFeedInitialState,
      changeMonthFeedState,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
