import { Ref } from 'vue';

import { DROPDOWN_MARGIN, MIN_DROPDOWN_HEIGHT } from '@/ui/types/constants';
import {
  isBoolean,
  isNumber,
  joinString,
} from '@/utils';
import { getDropdownListHeight } from '@/ui/Dropdown/utils/getDropdownListHeight';

type TInlineStyle = {
  targetElementClientRect: Ref<DOMRect | null>,
  targetElementSubMenu: Ref<DOMRect | null>,
  optionsLength: number | null,
  dropdownMatchSelectWidth: number | boolean,
  isFixedOnBottomLeft: boolean,
  isFixedOnTopRight: boolean,
};

export const getInlineStyle = ({
  targetElementClientRect,
  targetElementSubMenu,
  optionsLength,
  dropdownMatchSelectWidth,
  isFixedOnBottomLeft,
  isFixedOnTopRight,
}: TInlineStyle) => {
  if (!targetElementClientRect) return '';
  const clientHeight = getDropdownListHeight(optionsLength);

  let top = targetElementClientRect.value?.bottom ? `top: ${targetElementClientRect.value.bottom + DROPDOWN_MARGIN}px;` : '';
  if (isFixedOnTopRight && targetElementClientRect.value) {
    // padding 8px (dropdown)
    top = `top: ${targetElementClientRect.value.top - 8}px;`;
  }
  // если Dropdown начинает упираться в окно браузера снизу, то он будет рендериться сверху контрола
  if (targetElementClientRect.value
    && (
      document.documentElement.clientHeight - targetElementClientRect.value.bottom < clientHeight + MIN_DROPDOWN_HEIGHT
    )) {
    // padding 4px [up + down] + margin 2px = 10
    const topPosition = targetElementClientRect.value.top - clientHeight - 10;
    top = `top: ${topPosition}px;`;
  }

  let left = targetElementClientRect.value?.left ? `left: ${targetElementClientRect.value.left}px;` : '';
  let width = '';
  if (isBoolean(dropdownMatchSelectWidth) && !dropdownMatchSelectWidth) {
    width = 'width: auto';
    /** зафиксировать Dropdown с правого края контрола, либо, если места нет, то с левого края */
    if (isFixedOnTopRight && targetElementSubMenu.value && targetElementClientRect.value) {
      // padding 6px (dropdown) + margin 2px = 8
      const spaceToRightOfDropdownList = document.documentElement.clientWidth - targetElementClientRect.value.right - 8;
      if (spaceToRightOfDropdownList < targetElementSubMenu.value.width) {
        // padding 6px (dropdown) + margin 2px = 8
        left = `left: ${targetElementClientRect.value.left - targetElementSubMenu.value.width - 8}px;`;
      } else if (spaceToRightOfDropdownList > targetElementSubMenu.value.width) {
        // padding 6px (dropdown) + margin 2px = 8
        left = `left: ${targetElementClientRect.value.left + targetElementClientRect.value.width + 8}px;`;
      }
    }
  } else if (isNumber(dropdownMatchSelectWidth)) {
    width = `min-width: ${dropdownMatchSelectWidth}px;`;
    if (targetElementClientRect.value) {
      if (isFixedOnBottomLeft) {
        /** зафиксировать Dropdown у левого края Select'а */
        left = `left: ${targetElementClientRect.value.left}px;`;
      } else {
        const rightSpace = document.documentElement.clientWidth - targetElementClientRect.value.left;
        if (
          rightSpace >= dropdownMatchSelectWidth
          /*
            проверка ниже нужна для маленьких по ширине экранов, когда целиком дропдаун не помещается по правую сторону от контрола,
            но рабочая область по правую сторону контрола всё еще больше, чем по левую
          */
          || rightSpace >= targetElementClientRect.value.right
        ) {
          /* если справа места больше, чем слева, Dropdown прижат к левому краю контрола */
          left = `left: ${targetElementClientRect.value.left}px;`;
        } else {
          /* Dropdown прижат к правому краю контрола */
          let leftPosition;
          const position = Math.abs(dropdownMatchSelectWidth - targetElementClientRect.value.width);
          if (dropdownMatchSelectWidth > targetElementClientRect.value.width) {
            leftPosition = targetElementClientRect.value.left - position;
          } else {
            leftPosition = targetElementClientRect.value.left + position;
          }
          left = leftPosition ? `left: ${leftPosition}px;` : '';
        }
      }
    }
  } else {
    width = targetElementClientRect.value?.width ? `max-width: ${targetElementClientRect.value.width}px; width: 100%` : '';
  }

  return joinString([top, left, width]);
};
