import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import tt from '@/i18n/utils/translateText';

export const getDescriptionByPlanCode = (planCode: EBillingPlanCode): string[] => {
  switch (planCode) {
    case EBillingPlanCode.cargoOwner:
      return [
        `${tt('billing.modal.creatingTenders')};`,
        `${tt('billing.modal.distributionOrdersByExecutors')};`,
        `${tt('billing.modal.controlOfExecutionAndDelivery')};`,
        tt('billing.modal.organizationOfTheEnterpriseCourtyardManagementProcess'),
      ];
    case EBillingPlanCode.shipper:
      return [
        `${tt('billing.modal.trackingAndControlOfVehicleArrivalAtLoadingAndUnloadingPoints')};`,
        `${tt('billing.modal.recordingTheDateAndTimeOfArrivalAndDepartureOfTheVehicle')};`,
        tt('billing.modal.creationAndSigningOfEtRN'),
      ];
    case EBillingPlanCode.carrierStart:
      return [
        `${tt('billing.modal.twoManagersForOrganization')};`,
        tt('billing.modal.accessToTenders'),
      ];
    case EBillingPlanCode.carrierBasic:
      return [
        `${tt('billing.modal.twoManagersForOrganization')};`,
        `${tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndTenTools')};`,
        `${tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate')};`,
        tt('billing.modal.accessToSelfBilling'),
      ];
    case EBillingPlanCode.carrierExtended:
      return [
        `${tt('billing.modal.unlimitedNumberOfManagersInTheOrganization')};`,
        `${tt('billing.modal.accessToResponsibleOnLookupAssignmentRules')};`,
        tt('billing.modal.transportationPriceCalculator'),
      ];
    case EBillingPlanCode.freightForwarderBasic:
      return [
        `${tt('billing.modal.twoManagersForOrganization')};`,
        `${tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndFifteenTools')};`,
        `${tt('billing.modal.republishingOrdersOnATI')};`,
        `${tt('billing.modal.subcontracting')};`,
        tt('billing.modal.accessToSelfBilling'),
      ];
    case EBillingPlanCode.freightForwarderExtended:
      return [
        `${tt('billing.modal.unlimitedNumberOfManagersInTheOrganization')};`,
        `${tt('billing.modal.accessToResponsibleOnLookupAssignmentRules')}`,
        tt('billing.modal.transportationPriceCalculator'),
      ];
    default:
      return [];
  }
};
