import { TDateAppFormat } from '@/types';
// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';

/**
 * Возвращает объект DayJs со строгим парсингом переданного значения и формата.
 *
 * При строгом парсинге, переданное значение с невалидной датой (к примеру, 31.02.2025) преобразовывается в не валидный объект DayJs.
 *
 * При не строгом невалидная дата 31.02.2025 преобразуется к 03.03.2025.
 * */
const getDayJsDateWithStrictParsing = ({
  date,
  format,
}: {
  date: string | number | TDateAppFormat,
  format?: string,
}) => dayjs(date, format, true);

export default getDayJsDateWithStrictParsing;
