export enum EPartnershipResourcesTabsKey {
  partnershipResourcesVehicles = 'partnershipResourcesVehicles',
  partnershipResourcesDrivers = 'partnershipResourcesDrivers',
}

export enum EPartnershipResourcesSubSections {
  vehicles = 'vehicles',
  drivers = 'drivers',
}

export const PARTNERSHIP_RESOURCES_SECTION = 'partnershipResources';

export const UPDATE_PARTNERSHIP_RESOURCE_STATUS_EVENT = 'updatePartnershipResourceStatus';
