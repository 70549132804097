import {
  COUNT_DAY_DIGITS,
  COUNT_MONTH_DIGITS,
  COUNT_YEAR_DIGITS,
} from './constants';
import { isDateSectionValueInvalid } from './isDateSectionValueInvalid';
import { TDateSections } from './types';

/**
 * Возвращает строку в формате YYYY-MM-DD, сформированную из полей аргумента date (day/month/year).
 * Если значения полей date не возможно преобразовать в число больше 0, возвращается null */
export const getFormattedDate = (date: TDateSections): string | null => {
  const { day, month, year } = date;

  if (isDateSectionValueInvalid(day)
    || isDateSectionValueInvalid(month)
    || isDateSectionValueInvalid(year)
  ) return null;

  return `${year?.padStart(COUNT_YEAR_DIGITS, '0')}-${month?.padStart(COUNT_MONTH_DIGITS, '0')}-${day?.padStart(COUNT_DAY_DIGITS, '0')}`;
};
