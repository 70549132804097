import { EStatusType } from '@/domains/constants';

export enum EPartnershipStatus {
  accepted = 'accepted',
  canceled = 'canceled',
  paused = 'paused',
}

export enum EPartnershipTargetType {
  partner = 'partner',
  group = 'group',
}

export enum EInvitationStatus {
  active = 'active',
  revoked = 'revoked',
  accepted = 'accepted',
  expired = 'expired',
  pending = 'pending',
}

export enum EPartnershipsRouteName {
  partnerships = 'partnerships',
  partnershipsPartners = 'partnershipsPartners',
  partnershipsInvitations = 'partnershipsInvitations',
  partnershipsContracts = 'partnershipsContracts',
  partnershipResources = 'partnershipResources',
  partnershipResourcesVehicles = 'partnershipResourcesVehicles',
  partnershipResourcesDrivers = 'partnershipResourcesDrivers',
}

export enum EPartnershipRouteName {
  partnershipInfo = 'partnershipInfo',
  partnershipsPartnerResources = 'partnershipsPartnerResources',
  partnershipsPartnerContracts = 'partnershipsPartnerContracts',
  partnershipsPartnerContacts = 'partnershipsPartnerContacts',
}

export enum EContractType {
  transportationContract = 'transportation_contract',
  transportExpeditionContract = 'transport_expedition_contract',
}

export const INVITATION_STATUS = {
  active: 'active',
  revoked: 'revoked',
  accepted: 'accepted',
  expired: 'expired',
  pending: 'pending',
};

export enum EPartnershipType {
  client = 'client',
  servicer = 'servicer',
}

export enum EPartnershipTypeFilter {
  all = 'all',
  client = 'client',
  servicer = 'servicer',
}

export const EVENT = { refreshInvitationsList: 'refreshInvitationsList' };

export const CONTRACT_STATUS_BADGE_DOT_COLOR = {
  active: EStatusType.success,
  inactive: EStatusType.error,
  archived: EStatusType.warning,
};

export enum EAcceptanceMethod {
  auto = 'auto',
  manual = 'manual',
}

export enum EPartnershipResourceStatus {
  /** Проверен (Заявлен) */
  verified = 'verified',
  /** Отклонен (в стоп-листе) */
  rejected = 'rejected',
  /** На паузе (приостановлен) */
  paused = 'paused',
  /** Не проверен */
  unverified = 'unverified',
  /** Ожидает проверки  */
  awaitingVerification = 'awaiting_verification',
  /** На проверке */
  verification = 'verification',
  archived = 'archived',
}

/** Дополнительные поля, заполняемые при смене статуса ресурса */
export enum EStatusChangeAdditionalParams {
  /** срок действия проверки */
  verificationExpiresAt = 'verificationExpiresAt',
  /** внешний комментарий (для исполнителя) */
  publicComment = 'publicComment',
}
