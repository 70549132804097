<template>
  <div class="attribute-info">
    <div class="attribute-info__title">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div
      class="attribute-info__content"
      :class="{ 'attribute-info__content_editable': editable }"
      @click="handleEditClick"
    >
      <slot v-if="value">
        {{ value }}
      </slot>
      <span v-else class="attribute-info__empty-label">
        <slot name="empty">{{ emptyText }}</slot>
      </span>
      <EditSvg v-if="editable" class="attribute-info__edit-icon" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import { ELabelDeclension } from '@/components/EmptyLabel/constants';
import EditSvg from '@/assets/svg/16x16/edit.svg';
import tt from '@/i18n/utils/translateText';

export default defineComponent({
  name: 'AttributeInfo',
  components: { EditSvg },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    emptyValue: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    emptyDeclension: {
      type: String as PropType<ELabelDeclension>,
      default: ELabelDeclension.neuter,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit'],
  setup(props, { emit }) {
    const handleEditClick = () => {
      if (props.editable) {
        emit('edit');
      }
    };

    const emptyText = computed(() => props.emptyValue || tt(`shared.notSpecified.${props.emptyDeclension}`));

    return {
      emptyText,
      handleEditClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
