import { TIsDateBeforeArguments } from '@/utils/dateUtils/domains/types';
// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';

const isDateBefore = ({
  firstDate,
  secondDate,
  unit,
  dateInUTC,
  timeZone,
}: TIsDateBeforeArguments) => {
  if (dateInUTC) {
    return (timeZone ? dayjs(firstDate).utc().tz(timeZone) : dayjs(firstDate).utc())
      .isBefore(timeZone
        ? dayjs(secondDate).utc().tz(timeZone)
        : dayjs(secondDate).utc(), unit,
      );
  }

  return (timeZone ? dayjs(firstDate).tz(timeZone) : dayjs(firstDate))
    .isBefore(timeZone ? dayjs(secondDate).tz(timeZone) : dayjs(secondDate), unit);
};

export default isDateBefore;
