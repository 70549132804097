import {
  computed,
  onMounted,
  reactive,
  Ref,
  watch,
} from 'vue';
import cloneDeep from 'lodash.clonedeep';

import logger from '@/logger';
import store from '@/store';
import { hasProperty, isString } from '@/utils';
import { TPagination } from '@/types';
import { SET_TABLE_PAGINATION } from '@/store/modules/pagination/mutation-types';
import { EPaginationFolders } from '@/store/modules/pagination/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { usePaginationStore } from '@/stores/pagination';

/*
  virtualPagination
  Нужно создать видимость для пользователя, что одновременно грузится большое кол-во данных
  и при этом грузить их пачками по virtualPageItems, чтобы снизить время ожидания загрузки таблицы.
  То есть значения в ui в пагинаторе должно остаться прежним (разбиение по pageItems и currentPage)
  и при этом на бекенд должны уходить обновленные pageItems=virtualPageItems и соответствующая ему currentPage.
*/

const VIRTUAL_PAGE_ITEMS = 100;

type TUsePaginationListReturn = {
  handlePageChange: (page: number, ...args: unknown[]) => void,
  handlePageSizeChange: (pageSize: number, ...args: unknown[]) => void,
  setFetchFunction: (fn) => void,
  setPagination: (newPagination: TPagination) => void,
  resetPagination: () => void,
  setFirstPage: () => void,
  setFirstVirtualPage: () => void,
  isLastPage: Ref<boolean>,
  isPaginationInitialized: Ref<boolean>,

  pagination: TPagination,
  virtualPagination: TPagination,
  // TODO: мб переименовать в fetchDataAndSetPagination ? Так больше смысла передаётся в названии
  fetchDataWithPagination: (...args: unknown[]) => Promise<unknown>,
  loadNextPage: (...args: unknown[]) => Promise<unknown>,
  setNextVirtualPage: () => void,
};

type TUsePaginationArguments = {
  initialPagination?: TPagination,
  fetchOnMount?: boolean,
  fetchFunctionInitialArgs?: unknown[],
  tableName?: string | Ref<string>,
  folderName?: EPaginationFolders,
  withCurrentPage?: boolean,
};

const getDefaultValues = () => ({
  initialPagination: {
    currentPage: 1,
    pageItems: 20,
    totalCount: null,
    totalPages: null,
  },
  fetchOnMount: true,
  fetchFunctionInitialArgs: [],
});

const reachPagination = (pagination: TPagination) => {
  const cloned: TPagination = cloneDeep(pagination);
  Object.entries(getDefaultValues().initialPagination).forEach(([key, value]) => {
    if (!hasProperty(cloned, key)) {
      cloned[key] = value;
    }
  });
  return cloned;
};

export const getVirtualCurrentPage = (currentPage: number, pageItems: number): number => {
  if (pageItems <= VIRTUAL_PAGE_ITEMS || currentPage === 1) return 1;
  return (pageItems / VIRTUAL_PAGE_ITEMS) * (currentPage - 1) + 1;
};

export const usePaginationList = ({
  initialPagination = getDefaultValues().initialPagination,
  fetchOnMount = true,
  fetchFunctionInitialArgs = [],
  tableName = '',
  folderName,
  withCurrentPage = true,
}: TUsePaginationArguments = getDefaultValues()): TUsePaginationListReturn => {
  const isPiniaPaginationStoreAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaPaginationStore),
  );

  const paginationStore = usePaginationStore();

  const computedTableName = computed(() => (isString(tableName) ? tableName : tableName.value));
  const storePagination = computed(() => {
    if (isPiniaPaginationStoreAvailable.value) {
      return folderName
        ? paginationStore.state?.[folderName]?.[computedTableName.value]
        : paginationStore.state?.[computedTableName.value];
    }

    return folderName
      ? store.state.pagination?.[folderName]?.[computedTableName.value]
      : store.state.pagination?.[computedTableName.value];
  });

  const reachedInitialPagination = {
    ...reachPagination(initialPagination),
    ...storePagination.value,
  };
  const pagination = reactive<TPagination>({ ...reachedInitialPagination });
  const virtualPagination = reactive<TPagination>({
    currentPage: getVirtualCurrentPage(reachedInitialPagination.currentPage, reachedInitialPagination.pageItems),
    pageItems: VIRTUAL_PAGE_ITEMS,
  });

  // eslint-disable-next-line unused-imports/no-unused-vars
  let fetchFunction = (...args) => Promise.resolve({ pagination });

  const setFetchFunction = (fn) => {
    if (typeof fn === 'function') {
      fetchFunction = fn;
    } else {
      logger.warn('[usePaginationList.setFetchFunction] Passed fn is not a function.');
    }
  };

  // Сетим первую виртуальную страницу с учетом значения физического пагинатора
  const setFirstVirtualPage = () => {
    virtualPagination.currentPage = getVirtualCurrentPage(pagination.currentPage, pagination.pageItems);
  };

  const setPagination = (newPagination: TPagination) => {
    const {
      currentPage, pageItems,
      totalCount, totalPages,
    } = newPagination;
    pagination.currentPage = currentPage;
    pagination.pageItems = pageItems;
    pagination.totalCount = totalCount;
    pagination.totalPages = totalPages;

    setFirstVirtualPage();

    const storePagination = {} as TPagination;

    storePagination.pageItems = pageItems;

    if (withCurrentPage) {
      storePagination.currentPage = currentPage;
    }

    if (computedTableName.value) {
      if (isPiniaPaginationStoreAvailable.value) {
        paginationStore.setTablePagination({
          folderName,
          tableName: computedTableName.value,
          pagination: storePagination,
        });
      } else {
        store.commit(`pagination/${SET_TABLE_PAGINATION}`, {
          folderName,
          tableName: computedTableName.value,
          pagination: storePagination,
        });
      }
    }
  };

  const resetPagination = () => {
    const nextPagination = {
      ...getDefaultValues().initialPagination,
      ...initialPagination,
    };

    setPagination(nextPagination);
  };

  const setFirstPage = () => {
    const paginationWithFirstPage = {
      currentPage: 1,
      pageItems: pagination.pageItems,
    } as TPagination;

    setPagination(paginationWithFirstPage);
  };

  const fetchDataWithPagination = (...args: unknown[]) => {
    setFirstVirtualPage();

    return fetchFunction(...args)
      .then((result) => {
        if (result?.pagination) {
          // чтобы засетить pagination необходимо вернуть значение response из fetchFunction
          setPagination(result.pagination);
        }

        return result;
      })
      .catch((error) => {
        logger.warn('[usePaginationList] Error while fetching data with pagination. Message: ', error?.message || error);
        throw error;
      });
  };

  const isLastPage = computed(() => (pagination.totalPages && pagination.currentPage
    ? pagination.currentPage >= pagination.totalPages
    : false));
  const isPaginationInitialized = computed(() => pagination.totalPages !== null && pagination.totalCount !== null);

  const handlePageChange = (page: number, ...args: unknown[]) => {
    pagination.currentPage = page;
    setFirstVirtualPage();
    fetchDataWithPagination(...args);
  };

  const handlePageSizeChange = (pageSize: number, ...args: unknown[]) => {
    pagination.pageItems = pageSize;
    pagination.currentPage = 1;
    setFirstVirtualPage();
    fetchDataWithPagination(...args);
  };

  const loadNextPage = (...args: unknown[]) => {
    pagination.currentPage = Number(pagination.currentPage) + 1;
    return fetchDataWithPagination(...args);
  };

  const setNextVirtualPage = () => {
    virtualPagination.currentPage += 1;
  };

  watch(computedTableName, () => {
    const { initialPagination } = getDefaultValues();
    pagination.pageItems = storePagination.value?.pageItems || initialPagination.pageItems;
    pagination.currentPage = storePagination.value?.currentPage || initialPagination.currentPage;
  });

  onMounted(() => {
    if (fetchOnMount) {
      fetchDataWithPagination(...fetchFunctionInitialArgs)
        .catch((error) => {
          logger.warn('[usePaginationList] Error while fetching data on mounted. Error: ', error);
        });
    }
  });

  return {
    isLastPage,
    isPaginationInitialized,
    pagination,
    virtualPagination,

    handlePageChange,
    handlePageSizeChange,
    setFetchFunction,
    setPagination,
    resetPagination,
    setFirstPage,
    setFirstVirtualPage,
    fetchDataWithPagination,
    loadNextPage,
    setNextVirtualPage,
  };
};
