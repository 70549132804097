import { DROPDOWN_PADDING_WITH_MARGIN } from '@/ui/types/constants';

import { getCSSProperty } from './getCSSProperty';

type TSetLeftWithoutMaxWidthParams = {
  targetElementClientRectRight?: number,
  targetElementClientRectLeft?: number,
  targetElementClientRectWidth?: number,
  targetElementSubMenuWidth?: number,
  isFixedOnTopRight: boolean,
};

export const setLeftWithoutMaxWidth = ({
  isFixedOnTopRight,
  targetElementSubMenuWidth,
  targetElementClientRectRight,
  targetElementClientRectLeft,
  targetElementClientRectWidth,
}: TSetLeftWithoutMaxWidthParams) => {
  let left = targetElementClientRectLeft ? getCSSProperty({
    key: 'left',
    value: targetElementClientRectLeft,
    unit: 'px',
  }) : '';

  const hasTargetElementClientRect = targetElementClientRectRight
    && targetElementClientRectLeft
    && targetElementClientRectWidth;

  /** зафиксировать Dropdown с правого края контрола, либо, если места нет, то с левого края */
  if (isFixedOnTopRight && targetElementSubMenuWidth && hasTargetElementClientRect) {
    // padding 6px (dropdown) + margin 2px = 8
    const spaceToRightOfDropdownList = document.documentElement.clientWidth - targetElementClientRectRight - DROPDOWN_PADDING_WITH_MARGIN;
    if (spaceToRightOfDropdownList < targetElementSubMenuWidth) {
      // padding 6px (dropdown) + margin 2px = 8
      left = getCSSProperty({
        key: 'left',
        value: targetElementClientRectLeft - targetElementSubMenuWidth - DROPDOWN_PADDING_WITH_MARGIN,
        unit: 'px',
      });
    } else if (spaceToRightOfDropdownList > targetElementSubMenuWidth) {
      // padding 6px (dropdown) + margin 2px = 8
      left = getCSSProperty({
        key: 'left',
        value: targetElementClientRectLeft + targetElementClientRectWidth + DROPDOWN_PADDING_WITH_MARGIN,
        unit: 'px',
      });
    }
  }

  return left;
};
