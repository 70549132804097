import tt from '@/i18n/utils/translateText';

export enum ECurrency {
  rub = 'RUB',
  usd = 'USD',
  eur = 'EUR',
  cny = 'CNY',
  byn = 'BYN',
}

export enum ECurrencyCode {
  rub = '643',
  usd = '840',
  eur = '978',
  cny = '156',
  byn = '933',
}

export const CURRENCY_TRANSLATION = {
  [ECurrency.rub]: tt('shared.currency.name.rub'),
  [ECurrency.usd]: tt('shared.currency.name.usd'),
  [ECurrency.eur]: tt('shared.currency.name.eur'),
  [ECurrency.cny]: tt('shared.currency.name.cny'),
  [ECurrency.byn]: tt('shared.currency.name.byn'),
};

export const CURRENCY_FULL_TRANSLATION = {
  [ECurrency.rub]: tt('shared.currency.fullName.rub'),
  [ECurrency.usd]: tt('shared.currency.fullName.usd'),
  [ECurrency.eur]: tt('shared.currency.fullName.eur'),
  [ECurrency.cny]: tt('shared.currency.fullName.cny'),
  [ECurrency.byn]: tt('shared.currency.fullName.byn'),
};

export const CURRENCY_SYMBOL_TRANSLATION = {
  [ECurrency.rub]: tt('shared.currency.symbol.rub'),
  [ECurrency.usd]: tt('shared.currency.symbol.usd'),
  [ECurrency.eur]: tt('shared.currency.symbol.eur'),
  [ECurrency.cny]: tt('shared.currency.symbol.cny'),
  [ECurrency.byn]: tt('shared.currency.symbol.byn'),
};

export const getCurrencySymbol = (currency: ECurrency | null | undefined) => (currency ? CURRENCY_SYMBOL_TRANSLATION[currency] : '');
export const getCurrencyName = (currency: ECurrency | null | undefined) => (currency ? CURRENCY_TRANSLATION[currency] : '');
export const getCurrencyFullName = (currency: ECurrency | null | undefined) => (currency ? CURRENCY_FULL_TRANSLATION[currency] : '');
export const getCurrencyNameWithSymbol = (currency: ECurrency | null | undefined) => (currency ? `${CURRENCY_TRANSLATION[currency]}, ${CURRENCY_SYMBOL_TRANSLATION[currency]}` : '');
