import { isBoolean, isNumber } from '@/utils';

import { getCSSProperty } from './getCSSProperty';

export const getDropdownWidth = (dropdownMatchSelectWidth: boolean | number, targetElementClientRectWidth: number) => {
  let width = '';
  if (isBoolean(dropdownMatchSelectWidth) && !dropdownMatchSelectWidth) {
    width = getCSSProperty({
      key: 'width',
      value: 'auto',
      unit: '',
    });
  } else if (isNumber(dropdownMatchSelectWidth)) {
    width = getCSSProperty({
      key: 'min-width',
      value: dropdownMatchSelectWidth,
      unit: 'px',
    });
  } else {
    width = targetElementClientRectWidth ? `${getCSSProperty({
      key: 'max-width',
      value: targetElementClientRectWidth,
      unit: 'px',
    })} ${getCSSProperty({
      key: 'width',
      value: '100',
      unit: '%',
    })}` : '';
  }

  return width;
};
