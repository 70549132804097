<template>
  <div class="dropdown-button">
    <Button
      ref="buttonRef"
      :title="title"
      :isActive="isDropdownVisible"
      :size="size"
      :type="type"
      @click.stop="handleDropdownToggle"
    >
      <template #icon>
        <slot name="buttonIcon" />
      </template>
    </Button>
    <div
      v-if="hasStatus"
      class="dropdown-button__status"
      :class="`dropdown-button__status_${size}`"
    />

    <Dropdown
      v-model:visible="isDropdownVisible"
      :targetElement="buttonHtmlRef"
      :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
      :withAutoClosing="!isFooterVisible"
      :withSearch="withSearch"
      :withoutOptions="withoutOptions"
      :notFoundContent="notFoundContent"
      :optionsLength="optionsLength"
      @search="handleSearch"
    >
      <slot name="dropdown" />
      <slot name="footer" />
    </Dropdown>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import {
  Button,
  Dropdown,
} from '@/ui';
import {
  EButtonType,
  ESize,
} from '@/ui/types';
import { useHasSlot } from '@/composables/useHasSlot';
import { EStatusType } from '@/domains/constants';

export default defineComponent({
  name: 'DropdownButton',
  components: {
    Button,
    Dropdown,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<EButtonType>,
      default: EButtonType.secondary,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    dropdownMatchSelectWidth: {
      type: [Boolean, Number] as PropType<boolean | number>,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: tt('shared.action.save'),
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    withoutOptions: {
      type: Boolean,
      default: true,
    },
    notFoundContent: {
      type: String,
      default: '',
    },
    optionsLength: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    hasStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'cancel', 'search'],
  setup(_, { emit, expose, slots }) {
    const buttonRef = ref<typeof Button | null>(null);
    const isDropdownVisible = ref<boolean>(false);

    const isFooterVisible = useHasSlot(slots, 'footer');

    const buttonHtmlRef = computed(() => (buttonRef.value
      ? (buttonRef.value as typeof Button).button
      : null));

    const handleDropdownToggle = () => {
      isDropdownVisible.value = !isDropdownVisible.value;
    };

    const handleActionClick = (emitName: 'save' | 'cancel') => {
      emit(emitName);
      isDropdownVisible.value = false;
    };

    const handleSearch = (event: string) => {
      emit('search', event);
    };

    expose({ handleDropdownToggle });

    return {
      buttonRef,
      buttonHtmlRef,
      isDropdownVisible,
      isFooterVisible,
      ESize,
      EButtonType,
      EStatusType,

      handleDropdownToggle,
      handleActionClick,
      tt,
      handleSearch,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
