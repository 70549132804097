import isNumber from '@/utils/isNumber';
import hasProperty from '@/utils/hasProperty';
import isObject from '@/utils/isObject';
import isString from '@/utils/isString';
import { TNormalizedPayload } from '@/types';

const normalizePayloadToDataIds = <T extends Record<string, unknown>>(payload: T[], field: string = 'id') => {
  if (!Array.isArray(payload)) return payload;

  const normalized = payload.reduce((result: TNormalizedPayload<T>, current: T) => {
    if (!isObject(current)) return result;

    const id = current[field] as string;

    if (!isString(id) && !isNumber(id)) return result;
    // Если в итоговом объекте еще нет такого ключа, то добавляем его
    if (!hasProperty(result.data, `${id}`)) {
      result.ids.push(id);
    }

    result.data[id] = current;

    return result;
  }, {
    data: {} as T,
    ids: [],
  } as TNormalizedPayload<T>);

  return normalized;
};

export default normalizePayloadToDataIds;
