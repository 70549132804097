import { computed, Ref } from 'vue';

import tt from '@/i18n/utils/translateText';
import { COUNT_OF_BILLING_TRIAL_PERIOD_DAYS, EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { hasCarrierStarterWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';

type TUseView = {
  price: Ref<number | null>,
  hasInfoAboutTrialPeriod: Ref<boolean>,
  specialOfferPrice: Ref<number | null>,
  isActivePlan: Ref<boolean>,
};

export const useView = ({
  price,
  hasInfoAboutTrialPeriod,
  specialOfferPrice,
  isActivePlan,
}: TUseView) => {
  const isSpecialPlansForBillingAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.specialPlansForBilling),
  );

  const billingTrialPeriodInfoText = computed(
    () => (hasInfoAboutTrialPeriod.value
      ? `${tt('shared.first.plural')} ${tt('billing.firstDaysIsTrial',
        { param: { count: COUNT_OF_BILLING_TRIAL_PERIOD_DAYS } })
      }`
      : ''));

  const isSpecialOfferPriceShown = computed(
    () => isSpecialPlansForBillingAvailable.value
    && (hasCarrierWorkplaceSubscription() || hasCarrierStarterWorkplaceSubscription())
    && isActivePlan.value
    && specialOfferPrice.value !== null,
  );

  const isPriceFreeShown = computed(() => !price.value);

  const isPriceUnitShown = computed(
    () => (!isPriceFreeShown.value && !isSpecialOfferPriceShown.value) || isSpecialOfferPriceShown.value,
  );

  return {
    billingTrialPeriodInfoText,
    isSpecialOfferPriceShown,
    isPriceUnitShown,
    isPriceFreeShown,
  };
};
