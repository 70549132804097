<template>
  <div ref="monthRef" class="date-picker-month">
    <div
      class="date-picker-month__title"
      :style="{ opacity: isMonthSelected ? 0.1 : 1 }"
    >
      <label>{{ monthTitle }}</label>
    </div>

    <div class="date-picker-month__days">
      <div
        v-for="(day, index) in days"
        :key="index"
        class="date-picker-month__day"
        :data-disabled="!!day.isDisabled"
        :data-selected="!!day.isSelected"
        :data-hovered="!!day.isHovered"
        @click="handleDayClick(day)"
      >
        <label v-if="day.value">
          {{ day.value }}
        </label>

        <div
          v-if="day.isToday"
          class="date-picker-month__today-mark"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  toRefs,
} from 'vue';

import {
  TDataPickerDayState,
  EMonth,
} from '@/ui/types';
import { TDateSections } from '@/ui/DatePicker/domain/types';
import { TDateAppFormat } from '@/types';

import { useView } from './application/useView';

export default defineComponent({
  name: 'DatePickerMonth',
  components: {},
  props: {
    month: {
      type: Number as PropType<EMonth>,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    selectedDate: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    minDate: {
      type: [Object, null] as PropType<TDateAppFormat | null>,
      default: null,
    },
    maxDate: {
      type: [Object, null] as PropType<TDateAppFormat | null>,
      default: null,
    },
    isMonthSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select'],
  setup(props, { emit, expose }) {
    const monthRef = ref<HTMLDivElement | null>(null);

    const {
      month,
      year,
      selectedDate,
      minDate,
      maxDate,
    } = toRefs(props);

    const {
      days,
      monthTitle,
    } = useView({
      month,
      year,
      selectedDate,
      minDate,
      maxDate,
    });

    const handleDayClick = (day: TDataPickerDayState) => {
      if (day.isDisabled || !day.value) return;

      const date: TDateSections = {
        day: `${day.value}`,
        month: `${month.value}`,
        year: `${year.value}`,
      };

      emit('select', date);
    };

    expose({ monthRef });

    return {
      days,
      monthTitle,
      monthRef,

      handleDayClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
