import {
  computed,
  ref,
  watch,
} from 'vue';

import useStore from '@/store/useStore';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';
import { IS_LOADING } from '@/store/modules/tenants/mutation-types';
import { TDomainError } from '@/types';
import { useUserStore } from '@/stores';

export const useAppGlobalEntities = () => {
  const store = useStore();

  const isGlobalEntitiesLoading = ref(false);

  const isTenantLoading = computed(() => store?.getters['tenants/tenantLoading']);
  const userTenants = computed(() => store?.getters['tenants/availableUserTenants']);
  const isUserLoading = computed(() => useUserStore.isUserLoading);

  /** Если oneTimePassword === true, то fetchTenant - отдаст 403, т.к. не будет доступа к tenants
    * чтобы не делать ненужного запроса и не ловить ошибку - добавим проверку на oneTimePassword(OTP) */
  const isUserLoggedInByOTP = computed(() => useUserStore.user?.lockedSince && useUserStore.user?.oneTimePassword);

  const setGlobalEntitiesLoading = (isLoading: boolean) => {
    isGlobalEntitiesLoading.value = isLoading;
  };

  const fetchGlobalEntities = () => {
    if (useUserStore.user && !isUserLoggedInByOTP.value) {
      return store.dispatch('tenants/fetchTenants')
        .then(() => store.dispatch('tenants/fetchCurrentTenantInfo')
          .catch((error: TDomainError) => {
            throw error;
          })
          .finally(() => store.commit(`tenants/${IS_LOADING}`, false)))
        .catch((error: TDomainError) => {
          store.dispatch(`app/${RESET_AUTH_STATE}`);
          throw error;
        });
    }

    if (!useUserStore.user) {
      return useUserStore.loadUser()
        .then(() => {
          if (!isUserLoggedInByOTP.value) {
            return store.dispatch('tenants/fetchTenants')
              .then(() => store.dispatch('tenants/fetchCurrentTenantInfo')
                .catch((error: TDomainError) => {
                  throw error;
                })
                .finally(() => store.commit(`tenants/${IS_LOADING}`, false)),
              )
              .catch((error: TDomainError) => {
                throw error;
              });
          }
          return Promise.resolve();
        })
        .catch((error: TDomainError) => {
          store.dispatch(`app/${RESET_AUTH_STATE}`);
          throw error;
        });
    }

    return Promise.resolve();
  };

  // Смотрим на изменение стейта загрузки пользователя и тенантов
  // Сетим isGlobalEntitiesLoading для того чтобы избежать рывков в анимации лоадера приложения
  watch(() => [isUserLoading.value, isTenantLoading.value], ([isUserLoading, isTenantLoading]: boolean[]) => {
    setGlobalEntitiesLoading(isUserLoading || isTenantLoading);
  });

  return {
    userTenants,
    isGlobalEntitiesLoading,

    fetchGlobalEntities,
  };
};
