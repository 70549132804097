import { useUserStore } from '@/stores';

export default function useAppHeap() {
  const identify = () => {
    if (window.heap) {
      const user = useUserStore.user?.email || useUserStore.user?.id;
      if (user) {
        window.heap.identify(user);
      }
    }
  };

  return { identify };
}
