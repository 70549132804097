import {
  COUNT_MONTHS_IN_YEAR,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  EMonth,
} from '@/ui/types/constants';
import { getYearsFromDate } from '@/utils/dateUtils';

import { TDateSections, TGetMonthAndYearForFeedResult } from './types';
import { getFormattedDate } from './getFormattedDate';

/**
 * Возвращает месяц и год для ленты месяцев (для формирования отображаемых элементов "Месяц"), если из переданной даты можно извлечь месяц/год,
 * иначе возвращается `{ feedMonth: null, feedYear: null}`
 */
export const getMonthAndYearForFeed = (date: TDateSections): TGetMonthAndYearForFeedResult => {
  // Если из компонентов date нельзя получить корректную строку с датой
  if (!getFormattedDate(date)) {
    return {
      feedMonth: null,
      feedYear: null,
    };
  }

  const minFeedYear = getYearsFromDate(DEFAULT_MIN_DATE);
  const maxFeedYear = getYearsFromDate(DEFAULT_MAX_DATE);

  let feedYear = (date.year && +date.year) || minFeedYear;
  let feedMonth = (date.month && +date.month) || EMonth.january;

  if (feedYear > maxFeedYear) {
    feedMonth = COUNT_MONTHS_IN_YEAR;
    feedYear = maxFeedYear;
  } else if (feedYear < minFeedYear) {
    feedMonth = EMonth.january;
    feedYear = minFeedYear;
  }

  return {
    feedMonth,
    feedYear,
  };
};
