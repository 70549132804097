import tt from '@/i18n/utils/translateText';
import { TDateAppFormat } from '@/types';
import {
  getDateByStartOfPart,
  getDayJsDate,
  isDateAfter,
  isDateBefore,
  isValidDate,
} from '@/utils/dateUtils';
import { ETimeUnit } from '@/constants';

import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from '../types/constants';

/**
 * Валидирует переданное значение даты и возвращает `resolve` или `reject` `Promise`.
 *
 * `Resolve`. Если переданное значение даты (`value`) `null` или:
 * 1. является валидной датой;
 * 2. не выходит за пределы допустимого диапазона (01.01.1900 - 31.12.2099);
 * 3. если передана функция `isDateDisabled`, удовлетворяет заданным в функции условиям по доступности даты к выбору.
 *
 * `Reject`. Если не выполняется хотя бы одно условие из пунктов выше.
 */
export const datePickerValueValidator = ({
  value,
  isDateDisabled,
}: {
  value: TDateAppFormat | null,
  isDateDisabled?: ((data: TDateAppFormat) => boolean) | null,
}) => {
  if (!value) return Promise.resolve();

  const isDateValid = isValidDate({
    date: value,
    strict: true,
  });

  // Если введенная дата не валидная (к примеру, 30.02.2025 или 31.11.2025)
  if (!isDateValid) return Promise.reject(tt('shared.rule.incorrectDate'));

  const defaultMinDate = getDateByStartOfPart(getDayJsDate(DEFAULT_MIN_DATE), 'day');
  const defaultMaxDate = getDateByStartOfPart(getDayJsDate(DEFAULT_MAX_DATE), 'day');

  const isDateBeforeDefaultMinDate = isDateBefore({
    firstDate: value,
    secondDate: defaultMinDate,
    unit: ETimeUnit.day,
  });

  const isDateAfterDefaultMaxDate = isDateAfter(value, defaultMaxDate, ETimeUnit.day);

  // Если введенная дата выходит за пределы мин/макс значений по умолчанию
  if (isDateBeforeDefaultMinDate || isDateAfterDefaultMaxDate) return Promise.reject(tt('shared.rule.incorrectDate'));

  // Если передана функция определяющая доступность даты к выбору
  if (isDateDisabled) {
    return isDateDisabled(value) ? Promise.reject(tt('shared.rule.incorrectDate')) : Promise.resolve();
  }

  return Promise.resolve();
};
