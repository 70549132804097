import { TPaginationResetPayload } from '@/store/modules/pagination/types';
import store from '@/store';
import { useSearchStore } from '@/stores/search';
import { usePaginationStore } from '@/stores/pagination';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';

import { TDataForResetPagination } from './types';
import { DATA_FOR_RESET_PAGINATION } from './constants';

/**
 * Сбрасывает текущее значение страницы пагинатора для таблиц в сторе.
 *
 * Условия для сброса текущей страницы пагинатора у таблиц, имеющих поле поиска, следующие:
 * 1. Был переход между разделами. К примеру, из "Направлений" в "Гарантии";
 * 2. Поле поиска таблицы было не пустое.
 *
 * Пагинацию не сбрасываем, если было обновление страницы или если при переходе между разделами, поле поиска пустое для соответствующей таблицы.
 */
export const checkSearchStateAndResetPagination = () => {
  const isPiniaPaginationStoreAvailable = checkFeatureIsEnabled(EExperimentalFeatures.piniaPaginationStore);

  const paginationStore = usePaginationStore();
  const searchStore = useSearchStore();

  DATA_FOR_RESET_PAGINATION.forEach(
    ({ searchSection, paginationResetPayloads }: TDataForResetPagination) => {
      if (searchStore.state[searchSection]) {
        paginationResetPayloads.forEach((paginationResetPayload: TPaginationResetPayload) => {
          if (isPiniaPaginationStoreAvailable) {
            paginationStore.resetPagination(paginationResetPayload);
          } else {
            store.dispatch('pagination/resetPagination', paginationResetPayload);
          }
        });
      }
    });
};
