import { computed } from 'vue';

import useExpirationTimer from '@/composables/useExpirationTimer';
import { useUserStore } from '@/stores';

export const useView = () => {
  const user = computed(() => useUserStore.user);
  const isOTP = computed(() => user.value?.oneTimePassword);
  const passwordExpiresAt = computed(() => user.value?.passwordExpiresAt); // Дата валидности пароля
  const lockedSince = computed(() => user.value?.lockedSince); // Дата блокировки аккаунта

  // Таймер истечения срока валидности пароля
  // TODO: А что это за таймер и зачем он нужен? Это самый простой способ проверить, не истёк ли пароль?
  const passwordExpirationTimer = useExpirationTimer({
    expirationDateRef: passwordExpiresAt,
    labelOnFinish: '',
    suffix: '',
  }).expirationValue;

  return {
    user,
    passwordExpirationTimer,
    passwordExpiresAt,
    isOTP,
    lockedSince,
  };
};
