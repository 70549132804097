import { TDateAppFormat } from '@/types';
import {
  addDaysToDate,
  getCurrentDayJsDate,
  getDateByStartOfPart,
  isDateAfter,
  isDateBefore,
  isDateSame,
} from '@/utils/dateUtils';
import { ETimeUnit } from '@/constants';

import { TGetMinAndMaxDates, TGetMinAndMaxDatesResult } from './types';

/**
 * Возврашает объект с границами интервала дат, доступными к выбору.
 *
 * Для каждого из аргументов, если значение - дата, обнуляется предустановленное время на полночь переданного дня.
 * Так как в разные промежутки времени менялись часовые пояса (переход на летнее время),
 * без этих преобразований можем получить дату с установленным временем (HH:mm - 01:00).
 */
const getPreparedDates = (
  minDate: TDateAppFormat | null = null,
  maxDate: TDateAppFormat | null = null,
): TGetMinAndMaxDatesResult => ({
  minDate: minDate ? getDateByStartOfPart(minDate, 'day') : null,
  maxDate: maxDate ? getDateByStartOfPart(maxDate, 'day') : null,
});

/**
 * Возвращает объект с минимальной и максимальной датами, доступными для выбора в зависимости от переданной функции isDateDisabled,
 * которая определяет доступна ли дата к выбору.
 *
 * Если значения для дат в значении null, значит:
 * 1. Установлена не корректно дата в системе, которая меньше или больше минимальной или максимальной дат по умолчанию;
 * 2. Функция isDateDisabled содержит условие, которое блокирует все даты в диапазоне от минимальной до максимальной дат по умолчанию.
 * */
export const getMinAndMaxDates = ({
  defaultMinDate,
  defaultMaxDate,
  isDateDisabled,
}: TGetMinAndMaxDates): TGetMinAndMaxDatesResult => {
  let minDate: TDateAppFormat | null = null;
  let maxDate: TDateAppFormat | null = null;

  // Если функция isDateDisabled не была передана или минимальная и максимальная даты (по умолчанию) попадают в диапазон доступных к выбору
  if (!isDateDisabled || (!isDateDisabled(defaultMinDate) && !isDateDisabled(defaultMaxDate))) {
    return {
      minDate: defaultMinDate,
      maxDate: defaultMaxDate,
    };
  }

  const currentDate = getDateByStartOfPart(getCurrentDayJsDate(), 'day');

  const isDateBeforeDefaultMin = isDateBefore({
    firstDate: currentDate,
    secondDate: defaultMinDate,
    unit: ETimeUnit.day,
  });

  const isDateAfterDefaultMax = isDateAfter(currentDate, defaultMaxDate, ETimeUnit.day);

  // Текущая дата меньше минимальной или больше максимальной дат по умолчанию. Для случаев, когда время в системе не корректно или дожили до 01.01.2100
  if (isDateBeforeDefaultMin || isDateAfterDefaultMax) {
    return {
      minDate,
      maxDate,
    };
  }

  /**
   * Ниже выполняем поиск границ мин/макс. Есть 2 основные ветки проверок:
   * - когда текущий день входит в диапазон доступных к выбору дат;
   * - когда  текущий день НЕ входит в диапазон. Мин/макс даты будут или до или после текущего дня.
   */

  /** Если текущий день входит в диапазон доступных к выбору дат */
  if (!isDateDisabled(currentDate)) {
    let date = currentDate;

    if (!isDateDisabled(defaultMinDate)) {
      minDate = defaultMinDate;
    } else {
      /** Уменьшаем дату, пока она в допустимом диапазоне. */
      while (!isDateDisabled(date)) {
        minDate = date;
        date = date.subtract(1, 'day');
      }
    }

    date = currentDate;

    if (!isDateDisabled(defaultMaxDate)) {
      maxDate = defaultMaxDate;
    } else {
      /** Увеличиваем дату, пока она в допустимом диапазоне. */
      while (!isDateDisabled(date)) {
        maxDate = date;
        date = addDaysToDate(date, 1);
      }
    }

    /** Если текущий день НЕ входит в диапазон доступных к выбору дат */
  } else {
    let min: TDateAppFormat | null = null;
    let max: TDateAppFormat | null = null;
    /**
     * Отправной точкой поиска является текущая дата.
     * При инициализации и с каждой итерацией в цикле, для соответствующих переменных dateBeforeCurrent и dateAfterCurrent, вычитаем или прибавляем день, пока не найдем одну из границ.
     * Находим границу - уменьшаем или увеличиваем только одну из переменных:
     * - Если двигаясь в "прошлое" от текущей даты находим дату доступную к выбору, считаем эту дату максимально допустимой к выбору. Продолжаем искать минимальную.
     * - Если двигаясь в "будущее" от текущей даты находим дату доступную к выбору, считаем эту дату минимально допустимой к выбору. Продолжаем искать максимальную.
     */

    let dateBeforeCurrent = addDaysToDate(currentDate, -1);
    let dateAfterCurrent = addDaysToDate(currentDate, 1);

    // Флаги устанавливающие в каком направлении осуществляется поиск мин/макс дат относительно текущей даты
    let isDatesFindBeforeCurrent = true;
    let isDatesFindAfterCurrent = true;

    // Если мин дата по-умолчанию доступна к выбору
    if (!isDateDisabled(defaultMinDate)) {
      min = defaultMinDate;
      isDatesFindAfterCurrent = false;
      // Если макс дата по-умолчанию доступна к выбору
    } else if (!isDateDisabled(defaultMaxDate)) {
      max = defaultMaxDate;
      isDatesFindBeforeCurrent = false;
    }

    while (!min || !max) {
      const isDateSameDefaultMinDate = isDateSame({
        firstDate: dateBeforeCurrent,
        secondDate: defaultMinDate,
        unit: ETimeUnit.day,
      });

      // Если подошли к минимальному дню (по-умолчанию)
      if (isDateSameDefaultMinDate && isDatesFindBeforeCurrent) {
        if (isDateDisabled(dateBeforeCurrent)) {
          if (max) {
            min = addDaysToDate(dateBeforeCurrent, 1);

            return getPreparedDates(min, max);
          }

          if (!isDatesFindAfterCurrent) {
            return getPreparedDates();
          }

          isDatesFindBeforeCurrent = false;
        } else {
          // Если мин день по-умолчанию является единственным доступным к выбору, то возвращаем (dateBeforeCurrent, dateBeforeCurrent)
          return getPreparedDates(dateBeforeCurrent, max || dateBeforeCurrent);
        }
      }

      const isDateSameDefaultMaxDate = isDateSame({
        firstDate: dateAfterCurrent,
        secondDate: defaultMaxDate,
        unit: ETimeUnit.day,
      });

      // Если подошли к максимальному дню (по-умолчанию)
      if (isDateSameDefaultMaxDate && isDatesFindAfterCurrent) {
        if (isDateDisabled(dateAfterCurrent)) {
          if (min) {
            max = addDaysToDate(dateAfterCurrent, -1);

            return getPreparedDates(min, max);
          }

          if (!isDatesFindBeforeCurrent) {
            return getPreparedDates();
          }

          isDatesFindAfterCurrent = false;
        } else {
          // Если макс день по-умолчанию является единственным доступным к выбору, то возвращаем (dateAfterCurrent, dateAfterCurrent)
          return getPreparedDates(min || dateAfterCurrent, dateAfterCurrent);
        }
      }

      if (isDatesFindBeforeCurrent) {
        // Если dateBeforeCurrent доступна к выбору и максимальная еще не установлена
        if (!isDateDisabled(dateBeforeCurrent) && !max) {
          max = dateBeforeCurrent;
          isDatesFindAfterCurrent = false;

        // Если dateBeforeCurrent не доступна к выбору и максимальная установлена
        } else if (isDateDisabled(dateBeforeCurrent) && max) {
          min = addDaysToDate(dateBeforeCurrent, 1);
        }
      }

      if (isDatesFindAfterCurrent) {
        // Если dateAfterCurrent доступна к выбору и минимальная еще не установлена
        if (!isDateDisabled(dateAfterCurrent) && !min) {
          min = dateAfterCurrent;
          isDatesFindBeforeCurrent = false;
        // Если dateAfterCurrent не доступна к выбору и минимальная установлена
        } else if (isDateDisabled(dateAfterCurrent) && min) {
          max = addDaysToDate(dateAfterCurrent, -1);
        }
      }

      if (isDatesFindBeforeCurrent) {
        dateBeforeCurrent = addDaysToDate(dateBeforeCurrent, -1);
      }

      if (isDatesFindAfterCurrent) {
        dateAfterCurrent = addDaysToDate(dateAfterCurrent, 1);
      }
    }

    minDate = min;
    maxDate = max;
  }

  return getPreparedDates(minDate, maxDate);
};
