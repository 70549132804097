<template>
  <div
    class="toggle"
    :class="{
      'toggle-with-align-items': canShowIconWithTooltip,
    }"
    @click="$emit('click', $event)"
  >
    <label
      class="toggle__label"
      :class="`toggle_${size}`"
    >
      <input
        id="switch"
        type="checkbox"
        role="switch"
        class="toggle__input"
        :checked="checked"
        :aria-checked="checked"
        :disabled="disabled"
        @change="handleChange"
      />
    </label>
    <div v-if="title" :class="toggleTextClass">
      <span class="toggle__title">{{ title }}</span>
      <Tooltip v-if="canShowIconWithTooltip">
        <template #title>
          <div class="toggle__tooltip">
            <span>{{ hint }}</span>
          </div>
        </template>
        <InfoSvg class="toggle__icon" />
      </Tooltip>
      <span v-else class="toggle__hint">{{ hint }}</span>
    </div>
    <slot />
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import Tooltip from '@/ui/Tooltip/index.vue';
import InfoSvg from '@/assets/svg/16x16/info.svg';
import { ESize } from '@/ui/types';

export default defineComponent({
  name: 'Toggle',
  components: {
    InfoSvg,
    Tooltip,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    title: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  emits: [
    'change',
    /** Поддержка двустороннего связывания (v-model) свойства 'checked' */
    'update:checked',
    'click',
  ],
  setup(props, { emit }) {
    const canShowIconWithTooltip = computed(() => props.hint && props.size === ESize.small);

    const toggleTextClass = computed(() => (canShowIconWithTooltip.value ? 'toggle__text-with-tooltip' : 'toggle__text'));

    const handleChange = (event: Event) => {
      const targetChecked = (event.target as HTMLInputElement).checked;

      emit('update:checked', targetChecked);
      emit('change', event);
    };

    return {
      canShowIconWithTooltip,
      toggleTextClass,

      handleChange,
    };
  },
});
</script>
<style lang="scss" src="./styles.scss" />
