import { TDateAppFormat } from '@/types';
import isDayjsGuard from '@/utils/dateUtils/domains/guards';
import getMsFromDate from '@/utils/dateUtils/getters/getMsFromDate';
import { TGetIsSameDate } from '@/utils/dateUtils/domains/types';

const setMillisecondsToDate = <T extends TDateAppFormat | number | string>(date: T, value = 0): T extends TDateAppFormat
  ? TDateAppFormat
  : number => {
  if (isDayjsGuard(date)) {
    return date.clone().millisecond(value) as any;
  }
  return new Date(date).setMilliseconds(0) as any;
};

const setSecondsToDate = <T extends TDateAppFormat | number | string>(date: T, value = 0): T extends TDateAppFormat
  ? TDateAppFormat
  : number => {
  if (isDayjsGuard(date)) {
    return date.clone().second(value) as any;
  }
  return new Date(date).setSeconds(0) as any;
};

const getIsSameDate: TGetIsSameDate = (
  dateOne: TDateAppFormat | number | string,
  dateTwo: TDateAppFormat | number | string,
  { ignoreMs = false, ignoreSec = false } = {
    ignoreMs: false,
    ignoreSec: false,
  },
) => {
  let dateOneFormatted = dateOne;
  let dateTwoFormatted = dateTwo;
  if (ignoreMs) {
    dateOneFormatted = setMillisecondsToDate(dateOneFormatted);
    dateTwoFormatted = setMillisecondsToDate(dateTwoFormatted);
  }

  if (ignoreSec) {
    dateOneFormatted = setSecondsToDate(dateOneFormatted);
    dateTwoFormatted = setSecondsToDate(dateTwoFormatted);
  }
  return getMsFromDate(dateOneFormatted) === getMsFromDate(dateTwoFormatted);
};

export default getIsSameDate;
