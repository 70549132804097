export enum EExecutorsLookupsFeedViewTabs {
  new = 'new',
  bidding = 'bidding',
  finished = 'finished',
}

export const EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SECTION = 'executorsLookupsFeedView';
export const EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SELECT_NAME = 'lookupMethodType';

export enum EExecutorsLookupsFeedViewFilterSubSection {
  new = 'new',
  bidding = 'bidding',
  finished = 'finished',
}

export const CONNECTION_INSTRUCTION_LINK = 'https://confluence.trucker.group/manual/1-10-integratsiya-s-drugimi-ploshchadkami-2570722907.html';
