import {
  DROPDOWN_MARGIN,
  VIEWPORT_PADDING,
} from '@/ui/types/constants';
import {
  isBoolean,
  isNumber,
  joinString,
} from '@/utils';

import { getDropdownListHeight } from './getDropdownListHeight';
import { getCSSProperty } from './getCSSProperty';
import { setLeftWithoutMaxWidth } from './setLeftWithoutMaxWidth';
import { setLeftWithMaxWidth } from './setLeftWithMaxWidth';

type TInlineStyle = {
  targetElementClientRectBottom?: number,
  targetElementClientRectTop?: number,
  targetElementClientRectRight?: number,
  targetElementClientRectLeft?: number,
  targetElementClientRectWidth?: number,
  targetElementSubMenuHeight?: number,
  targetElementSubMenuWidth?: number,
  optionsLength: number | null,
  dropdownMatchSelectWidth: number | boolean,
  isFixedOnBottomLeft: boolean,
  isFixedOnTopRight: boolean,
};

export const getInlineStyleNew = ({
  targetElementClientRectBottom,
  targetElementClientRectTop,
  targetElementClientRectRight,
  targetElementClientRectLeft,
  targetElementClientRectWidth,
  targetElementSubMenuHeight,
  targetElementSubMenuWidth,
  optionsLength,
  dropdownMatchSelectWidth,
  isFixedOnBottomLeft,
  isFixedOnTopRight,
}: TInlineStyle) => {
  if (!targetElementClientRectTop) return '';
  // TODO: заменить getDropdownListHeight на minHeight (TDS-89)
  const clientHeight = targetElementSubMenuHeight || getDropdownListHeight(optionsLength);

  let height = '';

  /* Позиционирование дропдауна по вертикали */

  // Помещается ли дропдаун сверху + VIEWPORT_PADDING + DROPDOWN_MARGIN
  const canShowDropdownFitOnTheTop = targetElementClientRectTop > (clientHeight + VIEWPORT_PADDING + DROPDOWN_MARGIN);

  const setDropdownHeightAndTop = () => {
    height = getCSSProperty({
      key: 'height',
      value: targetElementClientRectTop - VIEWPORT_PADDING - DROPDOWN_MARGIN,
      unit: 'px',
    });
    top = getCSSProperty({
      key: 'top',
      value: VIEWPORT_PADDING,
      unit: 'px',
    });
  };

  // Рендерим дропдаун снизу targetElement + остступ DROPDOWN_MARGIN
  let top = targetElementClientRectBottom ? getCSSProperty({
    key: 'top',
    value: targetElementClientRectBottom + DROPDOWN_MARGIN,
    unit: 'px',
  }) : '';

  // Если необходимо отрендерить дропдаун сверху справа
  if (isFixedOnTopRight && targetElementClientRectTop) {
    if (canShowDropdownFitOnTheTop) {
      top = getCSSProperty({
        key: 'top',
        value: targetElementClientRectTop - DROPDOWN_MARGIN,
        unit: 'px',
      });
    } else {
      setDropdownHeightAndTop();
    }
  }

  // если Dropdown начинает упираться в окно браузера снизу + VIEWPORT_PADDING, то он будет рендериться сверху контрола
  if (targetElementClientRectBottom && targetElementClientRectTop
    && (
      document.documentElement.clientHeight - targetElementClientRectBottom < clientHeight + VIEWPORT_PADDING
    )) {
    if (canShowDropdownFitOnTheTop) {
      const topPosition = targetElementClientRectTop - clientHeight - DROPDOWN_MARGIN;
      top = getCSSProperty({
        key: 'top',
        value: topPosition,
        unit: 'px',
      });
    } else {
      setDropdownHeightAndTop();
    }
  }

  /* Позиционирование дропдауна по горизонтали */
  let left = targetElementClientRectLeft ? getCSSProperty({
    key: 'left',
    value: targetElementClientRectLeft,
    unit: 'px',
  }) : '';

  if (isBoolean(dropdownMatchSelectWidth) && !dropdownMatchSelectWidth) {
    left = setLeftWithoutMaxWidth({
      isFixedOnTopRight,
      targetElementSubMenuWidth,
      targetElementClientRectRight,
      targetElementClientRectLeft,
      targetElementClientRectWidth,
    });
  } else if (isNumber(dropdownMatchSelectWidth)) {
    left = setLeftWithMaxWidth({
      isFixedOnBottomLeft,
      targetElementClientRectLeft,
      targetElementClientRectRight,
      targetElementClientRectWidth,
      dropdownMatchSelectWidth,
    });
  }

  return joinString([top, left, height]);
};
