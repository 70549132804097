import {
  computed,
  Ref,
  ref,
  watch,
} from 'vue';

import {
  getCurrentDayJsDate,
  getDateByStartOfPart,
  getDayJsDate,
  getDayJsDateWithStrictParsing,
  getDaysFromDate,
  getMonthsFromDate,
  getYearsFromDate,
  isDayjsGuard,
  isValidDate,
} from '@/utils/dateUtils';
import {
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  EDatePickerValueFormat,
} from '@/ui/types/constants';
import { TDateAppFormat } from '@/types';
import { datePickerValueValidator } from '@/ui/utils';

import {
  COUNT_DAY_DIGITS,
  COUNT_MONTH_DIGITS,
  COUNT_YEAR_DIGITS,
  DEFAULT_DATE_STATE,
} from '../domain/constants';
import {
  TChangeDateStateArguments,
  TDateSections,
  TIsDateDisabledFunction,
} from '../domain/types';
import { getFormattedDate } from '../domain/getFormattedDate';
import { getMinAndMaxDates } from '../domain/getMinAndMaxDates';
import { isDateSectionValueInvalid } from '../domain/isDateSectionValueInvalid';

type TUseChangeDateState = {
  value: Ref<TDateAppFormat | null>,
  isDateDisabled: Ref<TIsDateDisabledFunction | null>,
};

export const useChangeDateState = ({
  value,
  isDateDisabled,
}: TUseChangeDateState,
) => {
  const selectedDateState = ref<TDateSections>({ ...DEFAULT_DATE_STATE });

  const defaultMinDate = getDateByStartOfPart(getDayJsDate(DEFAULT_MIN_DATE), 'day');
  const defaultMaxDate = getDateByStartOfPart(getDayJsDate(DEFAULT_MAX_DATE), 'day');

  const minDate = ref<TDateAppFormat | null>(null);
  const maxDate = ref<TDateAppFormat | null>(null);

  /**
   * Строка в формате YYYY-MM-DD, сформированная из полей selectedDateState (day/month/year).
   * Если значения полей selectedDateState не возможно преобразовать в число больше 0, возвращается null
  * */
  const selectedDateValue = computed(() => getFormattedDate(selectedDateState.value));

  /**
   * @param date - объект, содержащий фрагменты даты (день/месяц/год).
   * @param isDateFormatNeeded - флаг, указывающий на необходимость форматирования фрагментов даты (добавление недостающих нулей перед днем/месяцем/годом).
   */
  const changeDateState = ({
    newDateState,
    isDateFormatNeeded = true,
  }: TChangeDateStateArguments) => {
    if (isDateFormatNeeded) {
      selectedDateState.value = {
        day: newDateState.day ? `${newDateState.day}`.padStart(COUNT_DAY_DIGITS, '0') : null,
        month: newDateState.month ? `${newDateState.month}`.padStart(COUNT_MONTH_DIGITS, '0') : null,
        year: newDateState.year ? `${newDateState.year}`.padStart(COUNT_YEAR_DIGITS, '0') : null,
      };
    } else {
      selectedDateState.value = { ...newDateState };
    }
  };

  /**
   * Корректирует сегменты стейта (день/месяц/год) и возвращает строку,
   * если из сегментов можно сформировать дату в формате YYYY-MM-DD или null, если сегменты стейта не заполнены
   * */
  const prepareDateStateValue = () => {
    const newDateState = { ...selectedDateState.value };

    // Если из сегментов даты невозможно сформировать строку в нужном формате
    if (!selectedDateValue.value) {
      // Если все сегменты день/месяц/год пустые
      if (!newDateState.day && !newDateState.month && !newDateState.year) {
        return null;
      }

      // Ниже, при необходимости, корректируем сегменты (день/месяц/год) соответствующим значением из текущей даты

      const currentDate = getDateByStartOfPart(getCurrentDayJsDate(), 'day');

      if (isDateSectionValueInvalid(newDateState.day)) {
        newDateState.day = `${getDaysFromDate(currentDate)}`;
      }

      if (isDateSectionValueInvalid(newDateState.month)) {
        newDateState.month = `${getMonthsFromDate(currentDate) + 1}`;
      }

      if (isDateSectionValueInvalid(newDateState.year)) {
        newDateState.year = `${getYearsFromDate(currentDate)}`;
      }
    }

    changeDateState({ newDateState });

    return selectedDateValue.value;
  };

  /**
   * Валидирует выбранное значение даты и возвращает `resolve` или `reject` `Promise` (результат выполнения утилиты `datePickerValueValidator`)
   */
  const validateDateState = () => {
    if (!selectedDateValue.value) return Promise.resolve();

    const date = getDayJsDateWithStrictParsing({
      date: selectedDateValue.value,
      format: EDatePickerValueFormat.UTCformat,
    });

    return datePickerValueValidator({
      value: date,

      isDateDisabled: isDateDisabled.value,
    });
  };

  watch(value, () => {
    if (!value.value) {
      changeDateState({
        newDateState: DEFAULT_DATE_STATE,
        isDateFormatNeeded: false,
      });
    } else if (isDayjsGuard(value.value) && isValidDate({ date: value.value })) {
      const date: TDateSections = {
        day: `${getDaysFromDate(value.value)}`,
        month: `${getMonthsFromDate(value.value) + 1}`,
        year: `${getYearsFromDate(value.value)}`,
      };

      changeDateState({ newDateState: date });
    }
  }, { immediate: true });

  watch(isDateDisabled, () => {
    const { minDate: min, maxDate: max } = getMinAndMaxDates({
      defaultMinDate,
      defaultMaxDate,

      isDateDisabled: isDateDisabled.value,
    });

    minDate.value = min;
    maxDate.value = max;
  }, { immediate: true });

  return {
    selectedDateState,
    selectedDateValue,
    minDate,
    maxDate,

    validateDateState,
    changeDateState,
    prepareDateStateValue,
  };
};
