<template>
  <PaywallMessage
    class="billing-special-offer-paywall-message"
  >
    <template #text>
      <div class="billing-special-offer-paywall-message__content">
        <label>
          {{ tt('billing.specialOfferPaywallMessage.specialPlanConditions') }}
        </label>

        <Button
          :title="tt('shared.accessibly')"
          :size="ESize.small"
          :type="EButtonType.primary"
          :loading="isLoading"
          @click="handleSpecialOfferReadingConfirm"
        />
      </div>

      <BillingSpecialOfferDropdown
        v-if="specialOffer"
        class="billing-special-offer-paywall-message__dropdown"
        :specialOffer="specialOffer"
      />
    </template>
  </PaywallMessage>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

import { Button } from '@/ui';
import { ESize, EButtonType } from '@/ui/types';
import tt from '@/i18n/utils/translateText';
import PaywallMessage from '@/components/PaywallMessage/index.vue';

import BillingSpecialOfferDropdown from '../BillingSpecialOfferDropdown/index.vue';
import { useConfirmReadingSpecialOffer } from './application/useConfirmReadingSpecialOffer';

export default defineComponent({
  name: 'BillingSpecialOfferPaywallMessage',
  components: {
    Button,
    BillingSpecialOfferDropdown,
    PaywallMessage,
  },
  setup() {
    const {
      isLoading,
      specialOffer,

      handleSpecialOfferReadingConfirm,
    } = useConfirmReadingSpecialOffer();

    return {
      isLoading,
      specialOffer,
      ESize,
      EButtonType,

      tt,
      handleSpecialOfferReadingConfirm,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
