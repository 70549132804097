import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TTenantsResponse,
  TSessionWithTokensResponse,
  TCurrentTenantInfoResponse,
  TUserResponse,
  TAuthenticateUserPayload,
  TRegisterUserPayload,
  TResponsibleEmployeesResponse,
  TAuthenticateContractorUserPayload,
  TIntegrationsSettings,
  TNewTenantFormData,
  TUpdatePartnershipResourcesFeaturesPayload,
  TUpdateAdditionalSettingsPayload,
  TGetAdditionalSettingsResponse,
  TResponsibleEmployee,
} from '@/contexts/accountingContext/domain/types';
import { TReferenceDataResponse, TTenantFeatures } from '@/types';
import { convertSessionResponse } from '@/domains/convertSessionResponse';
import { TForgotPasswordPayload } from '@/views/Infrastructure/ForgotPassword/types';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

import { ABORT_SIGNAL_NAME } from './constants';
import { ELicenseRole } from '../domain/constants';

export const fetchOrganizationInfoByName = ({ name }: { name: string }) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/lists/organizations',
  payload: { name },
});

export const fetchTenantsForCurrentUser = () => sendApiRequest<TTenantsResponse>({
  method: httpMethods.GET,
  endpoint: '/user/tenants',
});

export const fetchTenantBankAccount = (tenantId: string) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/${tenantId}/bank_account`,
});

export const fetchTenantInfo = (tenantId: string) => sendApiRequest<TCurrentTenantInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/${tenantId}/info`,
  signalName: ABORT_SIGNAL_NAME.accountingContextFetchTenantInfo,
});

export const editTenantBankAccount = (payload: any, tenantId: string) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/${tenantId}/bank_account`,
  payload,
});

export const editTenantPublicOfferAccept = (payload: {
  planCode: EBillingPlanCode,
  publicOfferAccept: boolean,
}, tenantId: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/${tenantId}/public_offers/accept`,
  payload,
});

export const editTenantLegalEntity = (payload: any, tenantId: string) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/${tenantId}/legal_entity`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const registerNewTenant = ({ tenantAttributes }: { tenantAttributes: TNewTenantFormData }) => {
  const {
    name,
    inn,
    kpp,
    taxType,
    phone,
    legalAddress,
  } = tenantAttributes;

  const payload = {
    name,
    inn,
    kpp,
    taxType,
    phone,
    legalAddress,
    bank: {
      address: tenantAttributes.bankAddress,
      inn: tenantAttributes.bankInn,
      kpp: tenantAttributes.bankKpp,
      name: tenantAttributes.bankName,
      bik: tenantAttributes.bankBik,
      checkingAccount: tenantAttributes.bankCheckingAccount,
      correspondentAccount: tenantAttributes.bankCorrespondentAccount,
    },
  };

  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/user/tenants',
    payload,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
};

export const fetchUser = () => sendApiRequest<TUserResponse>({
  method: httpMethods.GET,
  endpoint: '/user',
});

/** Возвращает всех логистов заказчика */
export const fetchAllResponsibleEmployees = (params: {
  page: number,
  items: number,
} | null) => sendApiRequest<TResponsibleEmployee[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/responsible_employees',
  params,
  signalName: `${ABORT_SIGNAL_NAME.accountingContextFetchAllResponsibleEmployees}_${params?.page}`,
});

export const fetchExecutorEmployees = () => sendApiRequest<TResponsibleEmployeesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/executor_employees',
});

export const registerUser = async (payload: TRegisterUserPayload) => {
  const response = await sendApiRequest<TSessionWithTokensResponse>({
    method: httpMethods.POST,
    endpoint: '/user',
    payload,
    withoutAuth: true,
  });
  return convertSessionResponse(response);
};

export const registerUserBySberBusinessAccount = async (payload: TRegisterUserPayload) => {
  const response = await sendApiRequest<TSessionWithTokensResponse>({
    method: httpMethods.POST,
    endpoint: '/user/oauth/registration/sber_business',
    payload,
    withoutAuth: true,
    withCredentials: true,
  });
  return convertSessionResponse(response);
};

export const authenticateUser = async (payload: TAuthenticateUserPayload | TAuthenticateContractorUserPayload) => {
  const response = await sendApiRequest<TSessionWithTokensResponse>({
    method: httpMethods.POST,
    endpoint: '/session',
    payload,
    withoutAuth: true,
  });
  return convertSessionResponse(response);
};

export const unlockAccount = (payload: { code: string }) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: '/user/lock',
  payload,
});

export const refreshAccessToken = async ({ refreshToken }: { refreshToken: string }) => {
  const response = await sendApiRequest<TSessionWithTokensResponse>({
    method: httpMethods.PATCH,
    endpoint: '/session',
    headers: { 'X-Refresh-Token': `Bearer ${refreshToken}` },
    withoutAuth: true,
    signalName: ABORT_SIGNAL_NAME.accountingContextRefreshAccessToken,
  });
  return convertSessionResponse(response);
};

export const signOut = ({ refreshToken }: { refreshToken: string }) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: '/session',
  headers: { 'X-Refresh-Token': `Bearer ${refreshToken}` },
});

export const forgotPassword = (payload: TForgotPasswordPayload) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/reset_passwords',
  payload,
  withoutAuth: true,
});

export const resetPassword = ({ password, resetPasswordToken }: {
  password: string,
  resetPasswordToken: string,
}) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: '/reset_passwords',
  payload: {
    password,
    resetPasswordToken,
  },
  withoutAuth: true,
});

export const fetchBankAccountsAutocomplete = (query: string) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/autocomplete/bank_accounts',
  params: { query },
});

export const editUserPersonalData = ({
  firstName,
  lastName,
  secondName,
}: {
  firstName: string,
  lastName: string,
  secondName: string,
}) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: '/user/personal_data',
  payload: {
    firstName,
    lastName,
    secondName,
  },
});

export const editUserPassword = ({ oldPassword, newPassword }: {
  oldPassword: string,
  newPassword: string,
}) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: '/user/password',
  payload: {
    oldPassword,
    newPassword,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editUserOTP = ({ newPassword }: { newPassword: string }) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: '/user/password',
  payload: { newPassword },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editUserPhone = ({ phone }: { phone: string }) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: '/user/phone',
  payload: { phone },
});

export const editTenantFeatures = (payload: TTenantFeatures) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: '/:tenant_id/features',
  payload,
});

export const editDataByField = (field: string, payload: any) => {
  switch (field) {
    case 'phone':
      return editUserPhone(payload);
    case 'fullName':
      return editUserPersonalData(payload);
    case 'password':
      return editUserPassword(payload);
    default:
      return Promise.reject();
  }
};

export const makeRequestToEmailChange = (newEmail: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/user/change_email_request',
  payload: { newEmail },
});

export const confirmEmailChange = (code: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/user/change_email_request/confirmation',
  payload: { code },
});

export const acceptInviteToCompany = (invitationId: string, code: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/user/invitations/${invitationId}/acceptance`,
  payload: { code },
});

// TODO: в каком контексте описывать этот сервис, который относится к общим данным системы ?
export const getReferenceData = () => sendApiRequest<TReferenceDataResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/reference_data',
});

export const getIntegrationsSettings = () => sendApiRequest<TIntegrationsSettings>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/integrations',
});

export const updateIntegrationsSettings = (payload: TIntegrationsSettings) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: '/:tenant_id/integrations',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const setLicenseRole = (licenseRole: ELicenseRole) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/license_roles',
  payload: { licenseRole },
});

export const updatePartnershipResourcesFeatures = (
  payload: TUpdatePartnershipResourcesFeaturesPayload,
) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: '/:tenant_id/partnership_resources_features',
  payload,
});

export const getAdditionalSettings = (
  payload: TUpdateAdditionalSettingsPayload,
) => sendApiRequest<TGetAdditionalSettingsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/additional_settings',
  payload,
});

export const updateAdditionalSettings = (
  payload: TUpdateAdditionalSettingsPayload,
) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: '/:tenant_id/additional_settings',
  payload,
});
