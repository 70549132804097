import {
  NavigationGuardNext,
  RouteLocationNormalized,
  useRoute,
  useRouter,
} from 'vue-router';
import { ref, computed } from 'vue';

import useStore from '@/store/useStore';
import {
  EAppState,
  ROUTE_TYPE,
} from '@/constants';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import logger from '@/logger';
import { isRouteSectionChange } from '@/utils/isRouteSectionChange';
import { useSearchStore } from '@/stores/search';
import { checkSearchStateAndResetPagination } from '@/domains/stores/pagination/checkSearchStateAndResetPagination';

export const useAppGeneralNavigation = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const redirectPathToSignIn = computed(() => {
    const isAuthByEntryCode = store.getters['app/isAuthByEntryCode'];
    const { dataBeforeAuth } = store.state.app;

    return isAuthByEntryCode
      ? `/contractor_personal_account/sign_in?entry_uid=${dataBeforeAuth?.entryUid}`
      : '/sign_in';
  });

  const subscribeRouterGuards = () => {
    router.beforeEach((to, from, next) => {
      const { state } = store.state.app;
      const { dataBeforeAuth } = store.state.app;
      const isAuthByEntryCode = store.getters['app/isAuthByEntryCode'];

      if (state === EAppState.authenticated) {
        if (to.meta.type === ROUTE_TYPE.unauthenticated) {
          logger.log('[Router Guard] Navigate to /');
          router.push('/');
          return;
        }
      } else if (state !== EAppState.checking && to.meta.type === ROUTE_TYPE.private) {
        logger.log('[Router Guard] Navigate to sign_in');
        // если не авторизован и находится на private роуте, редиректим на Регистрацию
        if (isAuthByEntryCode) {
          router.push(`/contractor_personal_account/sign_in?entry_uid=${dataBeforeAuth?.entryUid}`);
        } else {
          router.push('/sign_in');
        }
        return;
      }
      /**
       * Т.к. в next() описан warn(`The "next" callback was called more than once in one navigation
       * guard when going from "${from.fullPath}" to "${to.fullPath}". It should be called exactly
       * one time in each navigation guard. This will fail in production.`)
       * добавляем проверку
       */
      if (from.fullPath !== to.fullPath) {
        next();
      }
    });
  };

  const checkRouteSectionChangeAndResetStore = () => {
    router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      const searchStore = useSearchStore();

      if (isRouteSectionChange(from, to) && searchStore.hasSearchQueries()) {
        checkSearchStateAndResetPagination();

        searchStore.$reset();
      }

      next();
    });
  };

  const initializeRouter = () => router.isReady()
    .then(subscribeRouterGuards)
    .then(checkRouteSectionChangeAndResetStore)
    .catch(() => {});

  const isRouteUnauthorized = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.unauthenticated;
  const isRoutePublic = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.public;
  const isAdminRoute = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.admin;
  const isPrivateRoute = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.private;

  const isMainRoute = (appRoute) => appRoute?.path === '/';
  const isInvitationAcceptanceRoute = (appRoute) => appRoute?.name === 'invitationAcceptance';
  const isTenantsListEmpty = (tenants) => tenants.length === 0;

  const isNavigationAfterSignInInProgress = ref(false);

  // TODO: требуется перепроверить все условия и упростить логику
  const commonRouteHandler = () => {
    const tenants = store.state.tenants.userTenants;
    const { currentTenant } = store.state.tenants;

    /**
     * Если роут для принятия приглашения к подключению к TMS (переход по ссылке из письма)
     */
    if (isInvitationAcceptanceRoute(route)) return;

    if (isTenantsListEmpty(tenants)) {
      router.push('/').then(() => {
        isNavigationAfterSignInInProgress.value = false;
      });
    } else if (currentTenant) {
      if (!isMainRoute(route)) return;
      redirectToInitialRoute()
        .then(() => {
          isNavigationAfterSignInInProgress.value = false;
        })
        .catch(() => {});
    } else {
      router.push('/').then(() => {
        isNavigationAfterSignInInProgress.value = false;
      });
    }
  };

  const navigateAfterSignIn = () => {
    commonRouteHandler();
  };

  const navigateAfterLogout = () => {
    if (isPrivateRoute(route)) {
      const lastPathBeforeAuth = !isMainRoute(route) ? route.fullPath : null;
      store.commit('app/SET_LAST_PATH_BEFORE_AUTH', lastPathBeforeAuth);
    }
    router.push(redirectPathToSignIn.value);
  };

  const navigateToMaintenance = () => {
    router.push('/maintenance');
  };

  const navigateAfterFailedAuthentication = () => {
    if (!isRouteUnauthorized(route) && !isRoutePublic(route)) {
      navigateAfterLogout();
    }
  };

  return {
    isNavigationAfterSignInInProgress,
    redirectPathToSignIn,

    initializeRouter,
    navigateAfterSignIn,
    navigateAfterLogout,
    navigateToMaintenance,
    navigateAfterFailedAuthentication,
    isPrivateRoute,
    isAdminRoute,
  };
};
