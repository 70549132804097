import { EStatusType } from '@/domains/constants';

export const ROUTE_NAME = {
  selfBilling: 'selfBilling',
  selfBillingRegistry: 'selfBillingRegistry',
};

export enum ESelfBillingStatus {
  /** Черновик - реестр не опубликован и недоступен для заказчика */
  draft = 'draft',
  /** На проверке - реестр отправлен на проверку заказчику */
  verification = 'verification',
  /** Принят - реестр принят заказчиком */
  accepted = 'accepted',
  /** Аннулирован - реестр отозван заказчиком или исполнителем */
  revoked = 'revoked',
}

export enum ERegistryCustomResponseCode {
  runsAlreadyAddedToAnotherRegistry = 'DomainErrors::SelfBillingContext::RunsAlreadyAddedToAnotherRegistry',
  runsNotFinished = 'DomainErrors::SelfBillingContext::RunsNotFinished',
  runsWithDifferentCurrencies = 'DomainErrors::SelfBillingContext::RunsWithDifferentCurrencies',
  runsWithMissingRequiredAttributes = 'DomainErrors::SelfBillingContext::RunsWithMissingRequiredAttributes',
  cannotStartRegistryVerification = 'DomainErrors::SelfBillingContext::CannotStartRegistryVerification',
  registryHasDistancePriceRunsWithoutDistance = 'DomainErrors::SelfBillingContext::RegistryHasDistancePriceRunsWithoutDistance',
  runsWithDifferentPartnershipContracts = 'DomainErrors::SelfBillingContext::RunsWithDifferentPartnershipContracts',
}

export enum ESelfBillingVerificationStatus {
  accepted = 'accepted',
  errors = 'errors',
  pending = 'pending',
  skipped = 'skipped',
}

export enum ESelfBillingVerificationStage {
  verificationScanDocuments = 'verification_scan_documents', // проверка сканов документов
  verificationOriginalDocumentsExchange = 'verification_original_documents_exchange', // обмен оригиналами
  verificationOriginalDocuments = 'verification_original_documents', // проверка оригиналов документов
}

export const SELF_BILLING_STATUS_TYPES = {
  [ESelfBillingStatus.draft]: EStatusType.default,
  [ESelfBillingStatus.verification]: EStatusType.processing,
  [ESelfBillingStatus.accepted]: EStatusType.success,
  [ESelfBillingStatus.revoked]: EStatusType.error,
};
