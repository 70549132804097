<template>
  <div class="info-block-item">
    <p class="info-block-item__label">
      {{ label }}
    </p>
    <EmptyLabel
      v-if="isEmpty"
      class="info-block-item__empty"
      inTableCell
      :declension="declension"
    />
    <slot v-else>
      <span
        v-if="value || value === 0"
        class="info-block-item__value"
      >
        {{ value }}
      </span>
      <EmptyLabel
        v-else
        class="info-block-item__empty"
        inTableCell
        :declension="declension"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
} from 'vue';

import EmptyLabel from '@/components/EmptyLabel/index.vue';
import { ELabelDeclension } from '@/components/EmptyLabel/constants';

export default defineComponent({
  name: 'InfoBlockItem',
  components: { EmptyLabel },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, null] as PropType<string | number | null>,
      default: null,
    },
    declension: {
      type: String as PropType<ELabelDeclension>,
      default: ELabelDeclension.neuter,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" src="./styles.scss" />
