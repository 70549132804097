import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TReachedResponse } from '@/api/types';
import {
  TSessionWithTokensSerialized,
  TAuthenticateContractorUserPayload,
  TAuthenticateUserPayload,
  TUserResponse,
  TRegisterUserPayload,
} from '@/contexts/accountingContext/domain/types';
import {
  authenticateUser,
  fetchUser,
  registerUser,
  registerUserBySberBusinessAccount,
} from '@/contexts/accountingContext/services';
import { decodeJWT } from '@/utils';
import { EWasAuthBy } from '@/constants';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';
import { TCustomStore } from '@/store/types';

import { TDecodedAccessToken, TUserStore } from './types';

export const useUserStore = defineStore(
  'TMS_USER',
  (): TUserStore => {
    const isUserLoading = ref<boolean>(false);
    const user = ref<TUserResponse | null>(null);
    const sessionData = ref<TSessionWithTokensSerialized | null>(null);
    const decodedAccessToken = ref<TDecodedAccessToken>(null);
    const wasAuthBy = ref<EWasAuthBy | null>(null);

    /** Загрузить данные пользователя */
    const loadUser = async () => {
      isUserLoading.value = true;

      try {
        const response: TReachedResponse<TUserResponse> = await fetchUser();
        if (response?.data) {
          user.value = response.data;
        }
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        isUserLoading.value = false;
      }
    };

    /** Вход в систему */
    const signIn = async (
      payload: TAuthenticateUserPayload | TAuthenticateContractorUserPayload,
      /** TODO: аргумент vuexStore нужно выпилить вместе с переходом модуля app на Pinia (будущий FF piniaMigrationStoreApp) */
      vuexStore: TCustomStore) => {
      try {
        sessionData.value = await authenticateUser(payload);
        if (!sessionData.value?.accessToken) return null;
        decodedAccessToken.value = decodeJWT(sessionData.value.accessToken);

        /* Вход в общий ТМС(логин+пароль или логин+пароль+код компании) */
        if (decodedAccessToken.value?.user_id) {
          wasAuthBy.value = EWasAuthBy.loginPassword;
          /* Вход в ЛК Контрагента */
        } else if (decodedAccessToken.value?.entry_uid) {
          wasAuthBy.value = EWasAuthBy.entryCode;
        }

        if (!vuexStore) {
          throw new Error('Vuex Store is not available.');
        }

        if (!wasAuthBy.value || !sessionData.value) return null;
        vuexStore.dispatch('app/setWasAuthBy', wasAuthBy.value, { root: true });
        vuexStore.dispatch('app/setAuthTokens', sessionData.value, { root: true });

        /* Если вошли через логин + пароль, то запрашиваем данные пользователя
       * и сетим нужный app state(EAppState) в зависимости от данных юзера */
        if (wasAuthBy.value === EWasAuthBy.loginPassword) {
          try {
            await loadUser();
            vuexStore.dispatch('app/proceedAuthentication', user.value, { root: true });
          } catch (error: unknown) {
            vuexStore.dispatch(`app/${RESET_AUTH_STATE}`);
            throw error;
          }
        } else if (wasAuthBy.value === EWasAuthBy.entryCode) {
        // Если вошли через ЛК контрагента, то сетим app state(EAppState) вручную на "authenticated"
          vuexStore.dispatch('app/setAuthenticatedMode', undefined, { root: true });
        }

        return sessionData.value;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    };

    const signInBySocial = async (
      payload: TSessionWithTokensSerialized,
      /** TODO: аргумент vuexStore нужно выпилить вместе с переходом модуля app на Pinia (будущий FF piniaMigrationStoreApp) */
      vuexStore: TCustomStore,
    ) => {
      if (!payload.accessToken) return;
      wasAuthBy.value = EWasAuthBy.social;

      vuexStore.dispatch('app/setWasAuthBy', wasAuthBy.value, { root: true });
      vuexStore.dispatch('app/setAuthTokens', payload, { root: true });

      try {
        await loadUser();
        vuexStore.dispatch('app/proceedAuthentication', user.value, { root: true });
      } catch (error: unknown) {
        vuexStore.dispatch(`app/${RESET_AUTH_STATE}`);
        throw error;
      }
    };

    const signUp = async (
      payload: TRegisterUserPayload,
      isSignUpBySocial: boolean,
      /** TODO: аргумент vuexStore нужно выпилить вместе с переходом модуля app на Pinia (будущий FF piniaMigrationStoreApp) */
      vuexStore: TCustomStore,
    ) => {
      try {
        sessionData.value = isSignUpBySocial
          ? await registerUserBySberBusinessAccount(payload)
          : await registerUser(payload);
        if (!sessionData.value?.accessToken) return null;
        if (isSignUpBySocial) {
          // TODO: не ясно, нужно ли вызывать setWasAuthBy при дефолтной регистрации. Выяснить при FF piniaMigrationStoreApp
          vuexStore.dispatch('app/setWasAuthBy', EWasAuthBy.social, { root: true });
        }
        vuexStore.dispatch('app/setAuthTokens', sessionData.value, { root: true });
        try {
          await loadUser();
          vuexStore.dispatch('app/proceedAuthentication', user.value, { root: true });
        } catch (error: unknown) {
          vuexStore.dispatch(`app/${RESET_AUTH_STATE}`);
          throw error;
        }
        return sessionData.value;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    };

    const $reset = () => {
      user.value = null;
      sessionData.value = null;
      decodedAccessToken.value = null;
      wasAuthBy.value = null;
    };

    return {
      isUserLoading,
      user,
      decodedAccessToken,
      wasAuthBy,

      loadUser,
      signIn,
      signInBySocial,
      signUp,
      $reset,
    };
  });
