import * as accountingServices from '@/contexts/accountingContext/services';
import hasCurrentTenant from '@/domains/hasCurrentTenant';
import logger from '@/logger';

import * as types from './mutation-types';
import { TTenantsActions } from './types';

const actions: TTenantsActions = {
  selectTenant: ({ commit }, { tenant }) => {
    commit(types.SET_CURRENT_TENANT, tenant);
    commit(types.SET_LAST_TENANT_ID, tenant?.id);
    return Promise.resolve();
  },
  fetchTenants: ({ commit, state }) => accountingServices.fetchTenantsForCurrentUser()
    .then(({ data }) => {
      commit(types.SET_TENANTS, data);

      const selectFirstTenant = () => {
        if (data[0]) {
          commit(types.SET_CURRENT_TENANT, data[0]);
          commit(types.SET_LAST_TENANT_ID, data[0].id);
        }
      };

      const findByIdAndSelect = (tenantId: string) => {
        const currentTenantFromRequest = data?.find((tenant) => tenant.id === tenantId);
        if (currentTenantFromRequest) {
          commit(types.SET_CURRENT_TENANT, currentTenantFromRequest);
          commit(types.SET_LAST_TENANT_ID, currentTenantFromRequest.id);
        } else {
          selectFirstTenant();
        }
      };

      /* если в localStorage хранился выбранный тенант, то ищем его по ID и обновляем из ответа,
      * чтобы были актуальные данные по тенанту всегда в localStorage
      */
      if (state.currentTenant) {
        findByIdAndSelect(state.currentTenant.id);
      } else if (state.lastTenantId) {
        /**
         * если в localStorage нет выбранного тенанта, то есть айдишник последнего выбранного,
         * то ищем его в списке всех тенантов и выбираем
         */
        findByIdAndSelect(state.lastTenantId);
      } else {
        selectFirstTenant();
      }
      return data;
    }),
  fetchTenantsWithLoading: ({ commit, dispatch }) => {
    commit(types.IS_LOADING, true);
    return dispatch('fetchTenants')
      .finally(() => {
        commit(types.IS_LOADING, false);
      });
  },
  fetchCurrentTenantInfo: ({ commit, state }) => {
    if (!hasCurrentTenant()) {
      logger.warn('[fetchCurrentTenantInfo] Cannot fetch current tenant info. There is no current tenant in store.');
      return Promise.resolve();
    }
    if (!state.currentTenant?.id) return Promise.resolve();
    return accountingServices.fetchTenantInfo(state.currentTenant.id)
      .then((response) => {
        if (!response.data) return;
        commit(types.SET_TENANT_INFO, response.data);
      })
      .catch(() => {});
  },
  registerNewTenant: ({ dispatch }, tenantAttributes) => accountingServices
    .registerNewTenant({ tenantAttributes })
    .then(async () => {
      await dispatch('fetchTenants');
    })
    .catch(() => {}),
  resetState: ({ commit }) => {
    commit(types.RESET_STATE);
  },
  fetchReferenceData: ({ commit }) => accountingServices.getReferenceData()
    .then((response) => {
      if (!response.data) return;
      commit(types.SET_REFERENCE_DATA, response.data);
    })
    .catch(() => Promise.resolve()),
  updateEmployeeViewSettingsColumns: ({ commit }, payload) => {
    commit(types.UPDATE_EMPLOYEE_VIEW_SETTINGS_COLUMNS, payload);
  },
};

export default actions;
