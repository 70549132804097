<template>
  <ControlWrapper
    ref="wrapperRef"
    :tabindex="disabled ? undefined : 0"
    :class="[
      'date-picker',
      `date-picker_${size}`,
    ]"
    :size="size"
    :disabled="disabled"
    :isInvalid="isInvalid"
    :hasBorder="hasBorder"
    @focus="handleControlWrapperFocus"
  >
    <DatePickerInput
      ref="datePickerInputRef"
      :state="selectedDateState"
      :isInputDisabled="disabled"
      :size="size"
      :isInputActive="isDropdownVisible"
      :minDate="minDate"
      :maxDate="maxDate"
      @change="handleInputChange"
    />

    <Dropdown
      ref="dropdownRef"
      className="date-picker__dropdown"
      :targetElement="wrapperHtmlRef"
      :visible="isDropdownVisible"
      withoutOptions
      :dropdownMatchSelectWidth="256"
      isFixedOnBottomLeft
      @blur="handleDropdownBlur"
    >
      <div class="date-picker__dropdown-header">
        <Select
          v-model:value="monthSelectValue"
          class="date-picker__select date-picker__select-month"
          :hasBorder="false"
          :options="monthsOptions"
          :size="ESize.small"
          :dropdownMatchSelectWidth="false"
          readonly
          @focus="handleSelectFocus"
          @blur="handleSelectBlur"
          @select="handleMonthSelect"
        >
          <template #addonBefore>
            <SelectorVerticalSvg />
          </template>
        </Select>

        <Select
          v-model:value="yearSelectValue"
          class="date-picker__select date-picker__select-year"
          :hasBorder="false"
          :options="yearsOptions"
          :size="ESize.small"
          :dropdownMatchSelectWidth="false"
          readonly
          @focus="handleSelectFocus"
          @blur="handleSelectBlur"
          @select="handleYearSelect"
        >
          <template #addonBefore>
            <SelectorVerticalSvg />
          </template>
        </Select>
      </div>

      <div class="date-picker__divider" />

      <DatePickerMonthFeed
        ref="datePickerMonthFeedRef"
        class="date-picker__month-feed"
        :selectedMonth="monthSelectValue"
        :selectedYear="yearSelectValue"
        :selectedDate="selectedDateValue"
        :minDate="minDate"
        :maxDate="maxDate"
        @select="handleDaySelect"
        @changeSelectedMonth="handleSelectedMonthChange"
      />

      <div class="date-picker__dropdown-footer">
        <Button
          class="date-picker__button"
          :type="EButtonType.ghostPrimary"
          :size="ESize.small"
          :title="tt('shared.today')"
          @click="handleCurrentDateSet"
        />
      </div>
    </Dropdown>

    <template #suffix>
      <CalendarSvg class="date-picker__icon" />
    </template>
  </ControlWrapper>
</template>

<script lang="ts">

import {
  PropType,
  computed,
  defineComponent,
  ref,
  toRefs,
} from 'vue';
import { Form } from 'ant-design-vue';

import SelectorVerticalSvg from '@/assets/svg/16x16/selector-vertical.svg';
import CalendarSvg from '@/assets/svg/16x16/calendar.svg';
import tt from '@/i18n/utils/translateText';
import { capitalize } from '@/utils';
import { TDateAppFormat } from '@/types';

import Button from '../Button/index.vue';
import ControlWrapper from '../ControlWrapper/index.vue';
import Dropdown from '../Dropdown/index.vue';
import Select from '../Select/index.vue';
import { EButtonType, ESize } from '../types';
import DatePickerInput from './components/DatePickerInput/index.vue';
import DatePickerMonthFeed from './components/DatePickerMonthFeed/index.vue';
import { useInteractWithDatePicker } from './application/useInteractWithDatePicker';
import { useChangeDropdownVisibility } from './application/useChangeDropdownVisibility';
import { useChangeDateState } from './application/useChangeDateState';
import { TIsDateDisabledFunction } from './domain/types';
import { useEmitDateValue } from './application/useEmitDateValue';

export default defineComponent({
  name: 'DatePicker',
  components: {
    Button,
    ControlWrapper,
    DatePickerInput,
    DatePickerMonthFeed,
    Dropdown,
    Select,
    SelectorVerticalSvg,
    CalendarSvg,
  },
  props: {
    value: {
      type: [Object, null] as PropType<TDateAppFormat | null>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    isDateDisabled: {
      type: [Function, null] as PropType<TIsDateDisabledFunction | null>,
      default: null,
    },
  },
  emits: ['update:value', 'change'],
  setup(props, { emit, expose }) {
    const {
      value,
      isDateDisabled,
    } = toRefs(props);

    /**
     * Для того, чтобы FormItem из Ant продолжал валидировать поля и отображать ошибки для компонентов из @/ui
     * необходимо получить из useInjectFormItemContext набор методов, которые необходимо вызывать
     * внутри аналогичных методов в компонентах @/ui.
     */
    const { onFieldChange, onFieldBlur } = Form.useInjectFormItemContext();

    const dropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);
    const wrapperRef = ref<InstanceType<typeof ControlWrapper> | null>(null);
    const datePickerInputRef = ref<InstanceType<typeof DatePickerInput> | null>(null);
    const datePickerMonthFeedRef = ref<InstanceType<typeof DatePickerMonthFeed> | null>(null);

    const wrapperHtmlRef = computed(() => (wrapperRef.value
      ? wrapperRef.value.wrapper
      : null));

    const {
      selectedDateState,
      selectedDateValue,
      minDate,
      maxDate,

      changeDateState,
      validateDateState,
      prepareDateStateValue,
    } = useChangeDateState({
      value,
      isDateDisabled,
    });

    const { emitDateValue } = useEmitDateValue({
      datePickerValue: value,

      changeDateState,
      emit,
      onFieldChange,
      onFieldBlur,
    });

    const {
      isDropdownVisible,

      handleDropdownBlur,
      handleSelectFocus,
      handleSelectBlur,
      handleDropdownClose,
      handleControlWrapperFocus,
      setDropdownFocus,
    } = useChangeDropdownVisibility({
      dropdownRef,
      datePickerInputRef,
    });

    const {
      yearsOptions,
      monthsOptions,
      monthSelectValue,
      yearSelectValue,

      handleCurrentDateSet,
      handleSelectedMonthChange,
      handleDaySelect,
      handleMonthSelect,
      handleYearSelect,
      handleInputChange,
    } = useInteractWithDatePicker({
      selectedDateState,
      minDate,
      maxDate,
      datePickerMonthFeedRef,
      isDropdownVisible,

      setDropdownFocus,
      handleDropdownClose,
      changeDateState,
      prepareDateStateValue,
      emitDateValue,
    });

    expose({ validate: validateDateState });

    return {
      dropdownRef,
      wrapperRef,
      wrapperHtmlRef,
      datePickerInputRef,
      datePickerMonthFeedRef,
      isDropdownVisible,
      yearsOptions,
      monthsOptions,
      yearSelectValue,
      monthSelectValue,
      selectedDateState,
      selectedDateValue,
      minDate,
      maxDate,
      EButtonType,
      ESize,

      tt,
      capitalize,
      handleDropdownBlur,
      handleSelectFocus,
      handleSelectBlur,
      handleCurrentDateSet,
      setDropdownFocus,
      handleSelectedMonthChange,
      handleDaySelect,
      handleMonthSelect,
      handleYearSelect,
      handleInputChange,
      handleControlWrapperFocus,
      validate: validateDateState,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
