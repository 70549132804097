import {
  computed,
  nextTick,
  Ref,
  ref,
} from 'vue';
import debounce from 'lodash.debounce';

import {
  COUNT_MONTHS_IN_YEAR,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  EMonth,
  EScrollBehavior,
} from '@/ui/types/constants';
import {
  getDateDiff,
  getDaysCountInCurrentMonth,
  getIsDateBetweenOrSame,
} from '@/utils/dateUtils';

import {
  TChangeMonthFeedState,
  TMonthElementState,
  TScrollToMonthArguments,
} from '../domain/type';
import DatePickerMonth from '../components/DatePickerMonth/index.vue';
import { useObserveViewportIntersection } from './useObserveViewportIntersection';
import {
  COUNT_MONTH_ELEMENTS,
  MAX_MONTH_ELEMENT_HEIGHT,
  SCROLL_DEBOUNCE_DELAY,
  SCROLL_OFFSET_FOR_SELECTED_MONTH,
} from '../domain/constants';

type TUseInteractWithMonthFeed = {
  monthElementsRefs: Ref<(InstanceType<typeof DatePickerMonth>[])>,
  feedViewportRef: Ref<HTMLElement | null>,
  monthElementsWrapperRef: Ref<HTMLElement | null>,
  selectedMonth: Ref<EMonth>,
  selectedYear: Ref<number>,
  emit: (event: 'changeSelectedMonth', ...args: unknown[]) => void,
};

export const useInteractWithMonthFeed = ({
  monthElementsRefs,
  feedViewportRef,
  monthElementsWrapperRef,
  selectedMonth,
  selectedYear,
  emit,
}: TUseInteractWithMonthFeed) => {
  const monthElements = ref<TMonthElementState[]>([]);

  /** Отступ сверху, для контейнера с компонентами в ленте месяцев, для работы виртуального скролла  */
  const translateYOffset = ref(0);

  /**
   * Признак того, что CSS свойство overflow имеет значение hidden для блока "Область просмотра" (feedViewportRef).
   *
   * Используется со значением 'true' в случаях, когда нужно временно отключить возможность прокрутки пользователем (к примеру, при программном скролле к выбранному месяцу).
  */
  const isFeedViewportOverflowHidden = ref(false);

  /**
   * Максимальная высота области прокрутки, от минимального до максимально возможного месяца.
   *
   * При достижении минимального или максимального месяца, происходит корректировка позиции скролла и отступа для контейнера с элементами,
   * так как не все элементы имеют высоту MAX_MONTH_ELEMENT_HEIGHT.
   * */
  const maxMonthFeedHeight = computed(() => {
    const diffMinAndMax = getDateDiff({
      firstDate: DEFAULT_MAX_DATE,
      secondDate: DEFAULT_MIN_DATE,
      unit: 'month',
      float: true,
    });

    return Math.ceil(diffMinAndMax) * MAX_MONTH_ELEMENT_HEIGHT;
  });

  /** Содержит ли первый элемент в списке месяцев минимально возможную дату (по умолчанию) */
  const isFirstMonthElementIncludeDefaultMinDate = computed(() => {
    if (!monthElements.value.length) return false;

    const { month, year } = monthElements.value[0];

    const dateFrom = `${year}-${month}-01`;

    const daysCount = getDaysCountInCurrentMonth(dateFrom);

    const dayTo = `${year}-${month}-${daysCount}`;

    return getIsDateBetweenOrSame(DEFAULT_MIN_DATE, dateFrom, dayTo);
  });

  /** Содержит ли последний элемент в списке месяцев максимально возможную дату (по умолчанию) */
  const isLastMonthElementIncludeDefaultMaxDate = computed(() => {
    if (!monthElements.value.length) return false;

    const { month, year } = monthElements.value[monthElements.value.length - 1];

    const dateFrom = `${year}-${month}-01`;

    const daysCount = getDaysCountInCurrentMonth(dateFrom);

    const dayTo = `${year}-${month}-${daysCount}`;

    return getIsDateBetweenOrSame(DEFAULT_MAX_DATE, dateFrom, dayTo);
  });

  // высота первого элемента в контейнере
  const firstMonthElementHeight = computed(() => monthElementsRefs.value?.[0].monthRef?.clientHeight || 0);

  const selectedMonthElement = computed(() => {
    const monthElementIndex = monthElements.value.findIndex((element: TMonthElementState) => element.isMonthSelected);

    return monthElementIndex !== -1 ? monthElementsRefs.value?.[monthElementIndex]?.monthRef || null : null;
  });

  const updateMonthElements = (selectedMonth: EMonth, selectedYear: number) => {
    const startMonthDate = `${selectedYear}-${selectedMonth}-01`;

    // разница (в месяцах) между минимально возможной датой и датой, соответсвующей первому числу выбранного месяца
    const diffBetweenMinAndCurrentDates = getDateDiff({
      firstDate: startMonthDate,
      secondDate: DEFAULT_MIN_DATE,
      unit: 'month',
    });

    // разница (в месяцах) между максимально возможной датой и датой, соответсвующей первому числу выбранного месяца
    const diffBetweenMaxAndCurrentDates = getDateDiff({
      firstDate: DEFAULT_MAX_DATE,
      secondDate: startMonthDate,
      unit: 'month',
    });

    /**
     * Смещение (индекс) выбранного месяца в списке, относительно начала списка.
     *
     * По-умолчанию, смещение: 2
     * [2 предыдущих месяца, Выбранный месяц, 2 следующих месяца]
     *
     * В документации (docs/development/implementation-details/date-picker.md) есть примеры списков, при разных значениях отступа.
     */
    let selectedMonthOffset = 2;

    /** Количество месяцев до и после выбранного месяца, при условии, что смещение selectedMonthOffset имеет значение по умолчанию */
    const countELementsAroundSelectedMonth = Math.floor(COUNT_MONTH_ELEMENTS / 2);
    const maxMonthElementIndex = COUNT_MONTH_ELEMENTS - 1;

    /**
     * Если разница между выбранным месяцем и минимально возможным месяцем, меньше величины countELementsAroundSelectedMonth,
     * изменяем смещение выбранного месяца в сторону начала списка
     */
    if (diffBetweenMinAndCurrentDates < countELementsAroundSelectedMonth) {
      selectedMonthOffset = diffBetweenMinAndCurrentDates;
    /**
     * Если разница между выбранным месяцем и максимально возможным месяцем, меньше величины countELementsAroundSelectedMonth,
     * изменяем смещение выбранного месяца в сторону конца списка
     */
    } else if (diffBetweenMaxAndCurrentDates < countELementsAroundSelectedMonth) {
      selectedMonthOffset = maxMonthElementIndex - diffBetweenMaxAndCurrentDates;
    }

    const months: TMonthElementState[] = [];

    for (let offset = 0 - selectedMonthOffset; offset < COUNT_MONTH_ELEMENTS - selectedMonthOffset; offset++) {
      const monthWithOffset = selectedMonth + offset;

      // Если месяц + смещение больше 12, месяц попадает в следующий год, необходимо скоректировать и месяц и год
      if (monthWithOffset > COUNT_MONTHS_IN_YEAR) {
        months.push({
          month: monthWithOffset - COUNT_MONTHS_IN_YEAR,
          year: selectedYear + 1,
        });
        // Если месяц + смещение меньше или равно 0, месяц попадает на предыдущий год, необходимо скоректировать и месяц и год
      } else if (monthWithOffset <= 0) {
        months.push({
          month: COUNT_MONTHS_IN_YEAR + monthWithOffset,
          year: selectedYear - 1,
        });
      } else {
        months.push({
          month: monthWithOffset,
          year: selectedYear,
          isMonthSelected: !offset,
        });
      }
    }

    monthElements.value = months;
  };

  const scrollToMonth = ({
    month,
    year,
    scrollBehavior = EScrollBehavior.instant,
  }: TScrollToMonthArguments,
  ) => {
    const monthElementIndex = monthElements.value.findIndex(
      (element: TMonthElementState) => element.month === month && element.year === year,
    );

    if (monthElementIndex === -1) return;

    const monthElement = monthElementsRefs.value?.[monthElementIndex]?.monthRef;

    if (monthElement) {
      const { offsetTop } = monthElement as HTMLDivElement;

      feedViewportRef.value?.scrollTo({
        top: translateYOffset.value + offsetTop + SCROLL_OFFSET_FOR_SELECTED_MONTH,
        behavior: scrollBehavior as ScrollBehavior,
      });
    }
  };

  /** Устанавливаем отступ сверху для контейнера с элементами */
  const setTopOffsetForMonthElementsWrapper = () => {
    if (!monthElements.value.length) {
      translateYOffset.value = 0;
    }

    const firstMonthOnFeed = monthElements.value[0];

    const date = `${firstMonthOnFeed.year}-${firstMonthOnFeed.month}-01`;

    const difMinAndMax = getDateDiff({
      firstDate: date,
      secondDate: DEFAULT_MIN_DATE,
      unit: 'month',
    });

    translateYOffset.value = difMinAndMax * MAX_MONTH_ELEMENT_HEIGHT;
  };

  /**
   * Компенсируем наличие пустого пространства сверху, при достижении минимально возможного месяца, при условии что месяц был выбранным.
   * Пустое пространство может остаться, так как при расчете высоты прокручиваемой области и начального отступа для контейнера,
   * использовалась максимально возможная высота для элемента "Месяц".
   */
  const scrollViewportToTop = () => {
    translateYOffset.value = 0;

    feedViewportRef.value?.scrollTo({ top: 0 });
  };

  /**
   * Компенсируем наличие пустого пространства снизу, при достижении максимально возможного месяца, при условии что месяц был выбранным.
   * Пустое пространство может остаться, так как при расчете высоты прокручиваемой области и начального отступа для контейнера,
   * использовалась максимально возможная высота для элемента "Месяц".
   */
  const scrollViewportToBottom = () => {
    if (!feedViewportRef.value || !monthElementsWrapperRef.value) return;

    const { scrollTop, scrollHeight } = feedViewportRef.value;

    /**
     * Новый оступ для контейнера с элементами.
     * Рассчитывается как разница между высотой области прокрутки, текущей высотой контейнера и половиной высоты элемента (оставляем область снизу для прокрутки)
     */
    const offset = scrollHeight - monthElementsWrapperRef.value.clientHeight - MAX_MONTH_ELEMENT_HEIGHT / 2;

    feedViewportRef.value?.scrollTo({ top: scrollTop + offset - translateYOffset.value });

    translateYOffset.value = offset;
  };

  /** Обработчик перехода к предыдущему месяцу в списке элементов (скролл вверх) */
  const handleChangeSelectedToPreviousMonth = () => {
    /**
     * Если первый элемент в списке месяцев является выбранным, значит достигли минимально допустимого месяца и необходимо переместить позицию скролла
     * в нулевую позицию. Корректировка необходима, так как начальная позиция скролла и отступа для контейнера с элементами,
     * рассчитывались с учетом максимально возможной высоты элемента (MAX_MONTH_ELEMENT_HEIGHT) и при достижении минимально допустимого месяца, над контейнером
     * могло оставаться пустое пространство.
     */
    if (monthElements.value[0].isMonthSelected) {
      scrollViewportToTop();

      return;
    }

    const month = selectedMonth.value === EMonth.january ? EMonth.december : selectedMonth.value - 1;
    const year = selectedMonth.value === EMonth.january ? selectedYear.value - 1 : selectedYear.value;

    emit('changeSelectedMonth', month, year);

    // Если в списке элементов последний элемент максимально возможный месяц.
    if (isLastMonthElementIncludeDefaultMaxDate.value) {
      updateMonthElements(month, year);

      nextTick(() => {
        // Если после обновления списка элементов, последний элемент больше не соответствует максимально возможному месяцу - обновляем отступ у контейнера с элементами
        if (!isLastMonthElementIncludeDefaultMaxDate.value) {
          translateYOffset.value -= firstMonthElementHeight.value;
        }

        observeMonthElement(selectedMonthElement.value);
      });

      return;
    }

    // Фиксируем отсутствие минимального месяца в ленте, до того как обновился список элементов
    const isNeedUpdateOffset = !isFirstMonthElementIncludeDefaultMinDate.value;

    updateMonthElements(month, year);

    nextTick(() => {
      if (isNeedUpdateOffset) {
        translateYOffset.value -= firstMonthElementHeight.value;
      }

      observeMonthElement(selectedMonthElement.value);
    });
  };

  /** Обработчик перехода к следующему месяцу в списке элементов (скролл вниз) */
  const handleChangeSelectedToNextMonth = () => {
    // Если последний элемент в списке месяцев является выбранным, значит мы достигли максимально допустимого месяца и дальнейших действий не требуется.
    if (monthElements.value[monthElements.value.length - 1].isMonthSelected) return;

    const month = selectedMonth.value === EMonth.december ? EMonth.january : selectedMonth.value + 1;
    const year = selectedMonth.value === EMonth.december ? selectedYear.value + 1 : selectedYear.value;

    emit('changeSelectedMonth', month, year);

    if (monthElements.value[monthElements.value.length - 1].month === month) {
      scrollViewportToBottom();
    }

    // Фиксируем наличие максимально возможного месяца в ленте, до того как обновился список элементов
    const isLastMonthMax = isLastMonthElementIncludeDefaultMaxDate.value;

    updateMonthElements(month, year);

    /**
     * Получаем высоту первого элемента в контейнере элементов до того, как изменится DOM с текущим списком элементов (при скролле вниз).
     * Это значение используем для расчета нового отступа для контейнера с элементами, чтобы лента с месяцами следовала за скроллом.
     */
    const firstElementHeight = firstMonthElementHeight.value;

    nextTick(() => {
      /**
       * Если список не содержит минимально и максимально возможные месяцы, необходимо обновить отступ у контейнера с элементами, на величину полученную
       * до обновления списка (на текущий момент, элемент у которого была получена высота, уже отсутствует в списке).
       */
      if (!isFirstMonthElementIncludeDefaultMinDate.value && !isLastMonthMax) {
        translateYOffset.value += firstElementHeight;
      }

      observeMonthElement(selectedMonthElement.value);
    });
  };

  const {
    initIntersectionObserver,
    observeMonthElement,
    unobserveMonthElement,
  } = useObserveViewportIntersection({
    handleChangeSelectedToPreviousMonth,
    handleChangeSelectedToNextMonth,
  });

  const setMonthFeedInitialState = () => {
    if (!feedViewportRef.value) return;

    // обновляем список месяцев
    updateMonthElements(selectedMonth.value, selectedYear.value);

    // устанавливаем отступ сверху для контейнера с элементами "Месяц"
    setTopOffsetForMonthElementsWrapper();

    // инициализируем наблюдателя за пересечением области просмотра
    initIntersectionObserver(feedViewportRef.value);

    nextTick(() => {
      scrollToMonth({
        month: selectedMonth.value,
        year: selectedYear.value,
      });

      observeMonthElement(selectedMonthElement.value);
    });
  };

  /**
   * Изменение состояния ленты с месяцами в соответствии с переданными аргументами (месяц и год).
   *
   * Изменяется список с отображаемыми месяцами, отступ для контейнера с элементами "Месяц" и происходит скролл к новому выбранному элементу.
   * Скорость скролла зависит от флага isScrollFeedInstant:
   * true - скролл мгновенный;
   * false - скролл плавный.
   */
  const changeMonthFeedState = ({
    month,
    year,
    isScrollFeedInstant = false,
  }: TChangeMonthFeedState) => {
    if (!feedViewportRef.value) return;

    // Фиксируем предыдущие значения месяц/год (selectedMonth и selectedYear будут соответствовать month и year на след tick).
    const prevSelectedMonth = selectedMonth.value;
    const prevSelectedYear = selectedYear.value;

    // Если предыдущий выбранный месяц совпадает с новым выбранный месяцем и пользователь не скроллил список (начальное положение у элемента не изменено) выходим
    if (selectedMonthElement.value && month === prevSelectedMonth && year === prevSelectedYear) {
      const { offsetTop } = selectedMonthElement.value;
      const { scrollTop } = feedViewportRef.value;

      // округление необходимо, так как появляются знаки после запятой, даже если изменять скролл только программно
      const scrollOffsetForSelectedMonth = Math.round(scrollTop - translateYOffset.value - offsetTop);

      if (scrollOffsetForSelectedMonth === SCROLL_OFFSET_FOR_SELECTED_MONTH) return;
    }

    // проверяем наличие до обновления списка
    const isMonthElementsContainMonth = !!monthElements.value.find(
      (monthElement: TMonthElementState) => monthElement.month === month && monthElement.year === year,
    );

    unobserveMonthElement(selectedMonthElement.value);

    // обновляем список месяцев
    updateMonthElements(month, year);

    // устанавливаем отступ сверху для контейнера с элементами "Месяц"
    setTopOffsetForMonthElementsWrapper();

    // Временно отключаем возможность прокрутки пользователем области просмотра, до того момента, пока не закончится программный скролл (scrollTo)
    isFeedViewportOverflowHidden.value = true;

    // Необходимо дождаться окончания скролла и только тогда подписаться на новый выбранный месяц
    feedViewportRef.value.addEventListener('scroll', handleScrollWithDebounce);

    nextTick(() => {
      // Если месяц, к которому нужно доскролить находится в текущем списке месяцев
      if (isMonthElementsContainMonth) {
        scrollToMonth({
          month,
          year,
          scrollBehavior: isScrollFeedInstant ? EScrollBehavior.instant : EScrollBehavior.smooth,
        });
      } else {
        /**
        * Разница (в месяцах) между датами, для выбора направления скролла, если отрицательная скролим сверху вниз
        *
        * В документации (docs/development/implementation-details/date-picker.md) есть детальное описание.
        */
        const diffBetweenDates = getDateDiff({
          firstDate: `${prevSelectedYear}-${prevSelectedMonth}-01`,
          secondDate: `${year}-${month}-01`,
          unit: 'month',
        });

        if (diffBetweenDates < 0) {
          const firstMonthElement = monthElements.value[0];

          // перемещаем позицию скролла к первому элементу в обновленном списке
          scrollToMonth({
            month: firstMonthElement.month,
            year: firstMonthElement.year,
          });
        } else {
          const lastMonthElement = monthElements.value[monthElements.value.length - 1];

          // перемещаем позицию скролла к последнему элементу в обновленном списке
          scrollToMonth({
            month: lastMonthElement.month,
            year: lastMonthElement.year,
          });
        }

        // плавно скролим к новому выбранному месяцу
        nextTick(() => {
          scrollToMonth({
            month,
            year,
            scrollBehavior: isScrollFeedInstant ? EScrollBehavior.instant : EScrollBehavior.smooth,
          });
        });
      }
    });
  };

  const handleScrollWithDebounce = debounce(() => {
    observeMonthElement(selectedMonthElement.value);

    isFeedViewportOverflowHidden.value = false;

    /**
     * Отписываемся от прослушивания события scroll (handleScrollWithDebounce это уже функция, которую вернула debounce, отписывание происходит корректно).
     * Если этого не сделать, обработчик будет в дальнейшем срабатывать на другие события скролла.
     */
    feedViewportRef.value?.removeEventListener('scroll', handleScrollWithDebounce);
  }, SCROLL_DEBOUNCE_DELAY);

  return {
    monthElements,
    translateYOffset,
    maxMonthFeedHeight,
    isFeedViewportOverflowHidden,

    setMonthFeedInitialState,
    changeMonthFeedState,
  };
};
