import { useRoute } from 'vue-router';

import store from '@/store';
import { TSubscription } from '@/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { hasCarrierStarterWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
import { ROUTE_TYPE } from '@/constants';

import { EBillingPlanCodeStatus } from './constants';

export const getIsBillingSpecialOfferPaywallMessageShown = () => {
  const route = useRoute();
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;

  const hasTenants = store.state.tenants.userTenants?.length > 0;

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;

  const activeSubscription = store.state.tenants.currentTenantInfo?.subscriptions?.find(
    (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null;

  return checkFeatureIsEnabled(EExperimentalFeatures.specialPlansForBilling)
  && isPrivateRoute
  && !hasInstanceLicense
  && hasTenants
  && (hasCarrierWorkplaceSubscription() || hasCarrierStarterWorkplaceSubscription())
  && !!activeSubscription?.specialOffer
  && !activeSubscription.specialOffer.confirmedAt;
};
