import { TIsDateAfter } from '@/utils/dateUtils/domains/types';
// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import { TDateAppFormat } from '@/types';

const isDateAfter: TIsDateAfter = (
  firsDate: number | TDateAppFormat | null | undefined | string,
  secondDate: number | TDateAppFormat | null | undefined | string,
  unit?: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year' | 'date',
  dateInUTC?: boolean,
  timeZone?: string) => {
  if (dateInUTC) {
    return (timeZone ? dayjs(firsDate).utc().tz(timeZone) : dayjs(firsDate).utc())
      .isAfter(timeZone
        ? dayjs(secondDate).utc().tz(timeZone)
        : dayjs(secondDate).utc(), unit,
      );
  }

  return (timeZone ? dayjs(firsDate).tz(timeZone) : dayjs(firsDate))
    .isAfter(timeZone ? dayjs(secondDate).tz(timeZone) : dayjs(secondDate), unit);
};

export default isDateAfter;
