import { createPinia, setActivePinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { useMigrateStoreData } from '@/composables/application/useMigrateStoreData';

import { useUserStore as useGlobalUserStore } from './user/index';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

/* Использование setActivePinia необходимо, когда нам нужно использовать Pinia за пределами Vue-компонентов до инициализации приложения.
Это требуется для того, чтобы явно установить "активный" экземпляр Pinia,
так как без инициализации Vue-приложения контекст Pinia по умолчанию отсутствует. */
setActivePinia(pinia);

/** Инициализируем в этом файле те хранилища,
 * данные которых нам нужны еще ДО полной инициализации приложения(например, модули app, user, tenants).
 * Под инициализацией подразумеваются все процессы настройки приложения,
 * которые происходят до момента подключения Pinia в приложение[App.use(pinia) в файле src/main.ts)]
 * В идеале, однако, код нужно организовать таким образом, чтобы использование Pinia происходило только после её полной инициализации,
 * тогда setActivePinia не понадобится */

/** Глобальный модуль хранилища user */
const useUserStore = useGlobalUserStore();

/** Миграция данных из стора vuex в стор pinia */
const { migrateStoreData } = useMigrateStoreData();

migrateStoreData('TMS_PAGINATION', 'pagination', 'state');

export { pinia, useUserStore };
