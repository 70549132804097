<template>
  <Select
    class="contractors-select"
    showSearch
    :placeholder="placeholder"
    :notFoundContent="isLoading ? undefined : tt('shared.emptyList')"
    :value="value"
    :loading="isLoading"
    :filterOption="filterOption"
    @select="handleSelect"
    @search="handleSearch"
  >
    <template v-if="isLoading" #notFoundContent>
      <Spin size="small" />
    </template>

    <template v-if="canAdd" #dropdownRender="{ menuNode: menu }">
      <VNodes :vnodes="menu" />
      <div class="contractors-select__additional-item" @click="handleAddClick">
        <Button class="contractors-select__add-button">
          <PlusSvg />
          {{ addText }}
        </Button>
      </div>
    </template>

    <DropdownItem
      v-for="item in items"
      :key="item.id"
      class="contractors-select__option"
      :value="item.id"
      :inn="item.inn"
      :kpp="item.kpp"
      :ogrn="item.ogrn"
      :name="item.name"
      :legalAddress="item.legalAddress"
      :phone="item.phone"
      :boxUid="item.boxUid"
    >
      <div class="contractors-select__row">
        <MarkedValue :value="getItemName(item)" :mark="query" />

        <MarkedValue
          v-if="item.inn"
          :value="`${tt('shared.inn')}: ${item.inn}`"
          :mark="query"
        />
      </div>

      <div v-if="item.kpp || item.ogrn" class="contractors-select__option-meta">
        <div v-if="item.kpp" class="contractors-select__kpp">
          {{ tt('shared.kpp') }}:
          <MarkedValue :value="item.kpp" :mark="query" />
        </div>

        <div v-if="item.clientUid" class="contractors-select__client-uid">
          <MarkedValue :value="item.clientUid" :mark="query" />
        </div>
        <div v-if="item.boxTitle">
          {{ tt('shared.boxTitle') }}:
          <MarkedValue :value="item.boxTitle" :mark="query" />
        </div>
      </div>
    </DropdownItem>
  </Select>
</template>
<script lang="ts">
import {
  Button,
  Select,
  Spin,
} from 'ant-design-vue';
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';

import { DropdownItem } from '@/ui';
import MarkedValue from '@/components/MarkedValue/index.vue';
import filterByInnKppName from '@/domains/filterByInnKppName';
import { TContractor, TContractorOption } from '@/contexts/contractorsContext/domain/types';
import tt from '@/i18n/utils/translateText';
import { TSelectOption } from '@/ui/types';
import PlusSvg from '@/assets/svg/16x16/plus.svg';

export default defineComponent({
  name: 'ContractorsSelect',
  components: {
    DropdownItem,
    PlusSvg,
    Select,
    Button,
    Spin,
    MarkedValue,
    VNodes: (any, { attrs }) => attrs.vnodes,
  },
  props: {
    addText: {
      type: String,
      default: tt('contractors.shared.action.addContractor'),
    },
    value: {
      type: [String, Number, Object, null] as PropType<string | number | TSelectOption | null>,
      default: null,
    },
    items: {
      type: Array as PropType<TContractor[]>,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    canAdd: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: tt('shared.contractorNameOrInn'),
    },
  },
  emits: [
    'addContractor',
    'select',
    'search',
  ],
  setup(props, { emit }) {
    const query = ref('');

    const handleAddClick = () => {
      emit('addContractor');
    };

    const filterOption = (query: string, option: TContractorOption) => {
      if (!query) return null;

      const { inn, kpp, name } = option;

      return filterByInnKppName(query, {
        inn,
        kpp,
        name,
      });
    };

    const handleSearch = (q: string) => {
      query.value = q;
      emit('search', q);
    };

    const handleSelect = (item: string) => {
      const option = props.items.find((i) => i.id === item);

      emit('select', item, option);
      handleSearch('');
    };

    const getItemName = (item: TContractor) => (item.inn ? `${`${item.name}, `}` : item.name);

    return {
      handleAddClick,
      handleSelect,
      filterOption,
      handleSearch,
      getItemName,
      tt,

      query,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
