import { TGetIsDateGreaterThan, TGetIsGreaterThan } from '@/utils/dateUtils/domains/types';
import { ETimeUnit } from '@/constants';
import getMsFromDate from '@/utils/dateUtils/getters/getMsFromDate';
import { TDateAppFormat } from '@/types';
import getSecondsFromDate from '@/utils/dateUtils/getters/getSecondsFromDate';
import getMinutesFromDate from '@/utils/dateUtils/getters/getMinutesFromDate';
import getHoursFromDate from '@/utils/dateUtils/getters/getHoursFromDate';
import getDaysFromDate from '@/utils/dateUtils/getters/getDaysFromDate';
import getMonthsFromDate from '@/utils/dateUtils/getters/getMonthsFromDate';
import getYearsFromDate from '@/utils/dateUtils/getters/getYearsFromDate';

const getIsDateGreaterByDaysThan: TGetIsGreaterThan = (
  dateOne: number | string | TDateAppFormat,
  dateTwo: number | string | TDateAppFormat) => {
  if (getYearsFromDate(dateOne) < getYearsFromDate(dateTwo)) return false;
  if (getYearsFromDate(dateOne) > getYearsFromDate(dateTwo)) return true;
  if (getMonthsFromDate(dateOne) > getMonthsFromDate(dateTwo)) return true;
  if (getMonthsFromDate(dateOne) < getMonthsFromDate(dateTwo)) return false;
  return getDaysFromDate(dateOne) > getDaysFromDate(dateTwo);
};

const getIsDateGreaterByHoursThan: TGetIsGreaterThan = (
  dateOne: number | string | TDateAppFormat,
  dateTwo: number | string | TDateAppFormat) => {
  if (getIsDateGreaterByDaysThan(dateOne, dateTwo)) return true;
  return getHoursFromDate(dateOne) > getHoursFromDate(dateTwo);
};

const getIsDateGreaterByMinutesThan: TGetIsGreaterThan = (
  dateOne: number | string | TDateAppFormat,
  dateTwo: number | string | TDateAppFormat) => {
  if (getIsDateGreaterByHoursThan(dateOne, dateTwo)) return true;
  return getMinutesFromDate(dateOne) > getMinutesFromDate(dateTwo);
};

const getIsDateGreaterBySecondsThan: TGetIsGreaterThan = (
  dateOne: number | string | TDateAppFormat,
  dateTwo: number | string | TDateAppFormat) => {
  if (getIsDateGreaterByMinutesThan(dateOne, dateTwo)) return true;
  return getSecondsFromDate(dateOne) > getSecondsFromDate(dateTwo);
};

const getIsDateGreaterThan: TGetIsDateGreaterThan = (
  dateOne: number | string | TDateAppFormat,
  dateTwo: number | string | TDateAppFormat,
  { precision = 'milliseconds' } = { precision: 'milliseconds' },
) => {
  switch (precision) {
    case ETimeUnit.day:
      return getIsDateGreaterByDaysThan(dateOne, dateTwo);
    case ETimeUnit.hour:
      return getIsDateGreaterByHoursThan(dateOne, dateTwo);
    case ETimeUnit.minute:
      return getIsDateGreaterByMinutesThan(dateOne, dateTwo);
    case ETimeUnit.second:
      return getIsDateGreaterBySecondsThan(dateOne, dateTwo);
    default:
      return getMsFromDate(dateOne) > getMsFromDate(dateTwo);
  }
};

export default getIsDateGreaterThan;
