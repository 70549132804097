import { defineStore } from 'pinia';
import {
  computed,
  reactive,
  ref,
} from 'vue';

import type { TDigitalQueueGates } from '@/contexts/contractorsContext/domain/types';
import { TDateAppFormat, TTableColumn } from '@/types';
import {
  EDigitalQueuePageTab,
  EDigitalQueuePageType,
  EDigitalQueuePageView,
} from '@/contexts/digitalQueuesContext/domain/constants';
import { TDigitalQueuePermissions } from '@/contexts/digitalQueuesContext/domain/types';
import { DATE_FORMAT } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { formatDate } from '@/utils/dateUtils';

import {
  Nullable,
  TColumnsSettings,
  TDigitalQueueSettingsStore,
  TGateSettings,
} from './types';

export const useDigitalQueueSettingsStore = defineStore(
  'TMS_DIGITAL_QUEUE_SETTINGS',
  (): TDigitalQueueSettingsStore => {
    const isRedesignBookingsTableAvailable = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.redesignBookingsTable),
    );

    const weekNumber = ref<string>('');
    const startDateOfCurrentWeek = ref<string>(''); // Дата начала выбранной недели
    const currentDigitalQueueId = ref<string>('');
    const openedGates = ref<TGateSettings[]>([]);
    const currentGateId = ref<string>('');
    const currentGates = ref<TDigitalQueueGates[]>([]);
    const selectedGateIds = ref<string[]>([]);
    // Удалить после переезда в новый контекст digitalQueuesContext
    const pageType = ref<EDigitalQueuePageType>(EDigitalQueuePageType.booking);
    // Удалить после переезда в новый контекст digitalQueuesContext
    const viewMode = ref<EDigitalQueuePageView>(EDigitalQueuePageView.timeline);
    const permissions = ref<TDigitalQueuePermissions | null>(null);
    const columnsSettings = ref<TColumnsSettings[]>([]);
    /** Табы "Настройка таймслотов" и "Редактирование шаблона" */
    const currentPageTab = ref<EDigitalQueuePageTab>(EDigitalQueuePageTab.timeSlots);
    /** Выбранная пользователем дата во вкладке "Таймслоты и бронирования" */
    const bookingDate = ref('');
    const bookingRangeDates = reactive<{ firstDate: string, secondDate: string }>({
      firstDate: '',
      secondDate: '',
    });
    const currentDigitalQueueTimeZone = ref('');

    const setDigitalQueueId = (id: Nullable<string>) => {
      currentDigitalQueueId.value = id || '';
    };

    const setDigitalQueueTimeZone = (timeZone: string) => {
      currentDigitalQueueTimeZone.value = timeZone;
    };

    const setWeekNumber = (newWeekNumber: string) => {
      weekNumber.value = newWeekNumber;
    };

    const setStartDateOfCurrentWeek = (date: string) => {
      startDateOfCurrentWeek.value = date;
    };

    const setOpenedGates = (gates: TGateSettings[]) => {
      openedGates.value = gates;
    };

    const setCurrentGates = (gates: TDigitalQueueGates[]) => {
      currentGates.value = gates;
    };

    const setCurrentGateId = (id: string) => {
      currentGateId.value = id;
    };

    const setPageType = (newPageType: EDigitalQueuePageType) => {
      pageType.value = newPageType;
    };

    const setViewMode = (newViewMode: EDigitalQueuePageView) => {
      viewMode.value = newViewMode;
    };

    const setViewPageType = (newPageTab: EDigitalQueuePageTab) => {
      currentPageTab.value = newPageTab;
    };

    const setGateSlotDuration = (payload: TGateSettings) => {
      const currentOpenedGate = openedGates.value.find((gate: TGateSettings) => payload.id === gate.id);
      if (currentOpenedGate) {
        currentOpenedGate.slotDuration = payload.slotDuration;
      } else {
        setOpenedGates([...openedGates.value, payload]);
        openedGates.value.find((gate: TGateSettings) => payload.id === gate.id)!.slotDuration = payload.slotDuration;
      }
    };

    const setPermissions = (payload: TDigitalQueuePermissions | null) => {
      permissions.value = payload;
    };

    const setColumnsSettings = (digitalQueueId: string, payload: TTableColumn[]) => {
      const foundDigitalQueue = columnsSettings.value.find((item: TColumnsSettings) => item.id === digitalQueueId);

      if (foundDigitalQueue) {
        const mergeMap = new Map(foundDigitalQueue.columnsSettings.map((item: TTableColumn) => [item.slug, item]));

        payload.forEach((item: TTableColumn) => {
          mergeMap.set(item.slug, item);
        });

        foundDigitalQueue.columnsSettings = Array.from(mergeMap.values());
      } else {
        columnsSettings.value.push({
          id: digitalQueueId,
          columnsSettings: payload,
        });
      }
    };

    const setBookingDate = (date: typeof DATE_FORMAT.UTCformat) => {
      bookingDate.value = date;
    };

    const setBookingRangeDate = (dates: [TDateAppFormat, TDateAppFormat]) => {
      const [firstDate, secondDate] = dates;

      bookingRangeDates.firstDate = formatDate(firstDate, { outputFormat: DATE_FORMAT.UTCformat });
      bookingRangeDates.secondDate = formatDate(secondDate, { outputFormat: DATE_FORMAT.UTCformat });
    };

    const setSelectedGateIds = (ids: string[]) => {
      selectedGateIds.value = ids;
    };

    const $reset = () => {
      currentDigitalQueueId.value = '';
      openedGates.value = [];
      currentGates.value = [];
      permissions.value = null;
      currentGateId.value = '';
      bookingDate.value = '';

      if (isRedesignBookingsTableAvailable.value) {
        bookingRangeDates.firstDate = '';
        bookingRangeDates.secondDate = '';
        currentDigitalQueueTimeZone.value = '';
      }
    };

    return {
      weekNumber,
      currentDigitalQueueId,
      currentDigitalQueueTimeZone,
      currentGateId,
      openedGates,
      currentGates,
      pageType,
      viewMode,
      permissions,
      columnsSettings,
      currentPageTab,
      startDateOfCurrentWeek,
      bookingDate,
      bookingRangeDates,
      selectedGateIds,

      setWeekNumber,
      setDigitalQueueId,
      setDigitalQueueTimeZone,
      setOpenedGates,
      setCurrentGates,
      setCurrentGateId,
      setPageType,
      setViewMode,
      setGateSlotDuration,
      setPermissions,
      setColumnsSettings,
      setViewPageType,
      setStartDateOfCurrentWeek,
      setBookingDate,
      setBookingRangeDate,
      setSelectedGateIds,

      $reset,
    };
  },
  { persist: true },
);
