import { Ref } from 'vue';

import { TDateAppFormat } from '@/types';
import { EDatePickerValueFormat } from '@/ui/types';
import {
  getDayJsDateWithStrictParsing,
  isDateSame,
} from '@/utils/dateUtils';
import { ETimeUnit } from '@/constants';

import { TChangeDateStateArguments } from '../domain/types';

type TUseEmitDateValue = {
  /** переданное значение даты в компонент */
  datePickerValue: Ref<TDateAppFormat | null>,
  changeDateState: ({ newDateState, isDateFormatNeeded }: TChangeDateStateArguments) => void,
  emit: (event: 'change' | 'update:value', ...args: unknown[]) => void,
  onFieldChange: () => void,
  onFieldBlur: () => void,
};

export const useEmitDateValue = ({
  datePickerValue,
  emit,
  onFieldChange,
  onFieldBlur,
}: TUseEmitDateValue) => {
  const emitDateValue = (dateStateValue: string | null) => {
    if (!dateStateValue) {
      // Если не было передано значение в компонент
      if (!datePickerValue.value) {
        onFieldBlur();
      } else {
        emit('change', null);
        emit('update:value', null);

        onFieldChange();
      }

      return;
    }

    // Если не было изменений
    if (datePickerValue.value && isDateSame({
      firstDate: dateStateValue,
      secondDate: datePickerValue.value,
      unit: ETimeUnit.day,
    })) {
      onFieldBlur();

      return;
    }

    const newDateValue = getDayJsDateWithStrictParsing({
      date: dateStateValue,
      format: EDatePickerValueFormat.UTCformat,
    });

    emit('change', newDateValue);
    emit('update:value', newDateValue);

    onFieldChange();
  };

  return { emitDateValue };
};
