export const THEMES = {
  apl: {
    ant: {
      primaryColor: '#00889A',
      errorColor: '#FB8FA2',
      warningColor: '#FAAD14',
      successColor: '#33D2AC',
      infoColor: '#00889A',
    },
    icons: {
      logo: 'apl',
      favicon: 'apl-favicon.svg',
      faviconNotification: 'apl-favicon-notification.svg',
    },
    variables: '_variables_apl.scss',
    welcomeLoaderProgress: {
      start: '#017280',
      end: '#A3D3D8',
    },
    jsColors: {
      green01: '#F6FFED',
      green03: '#B7EB8F',
      green06: '#1AC49C',
      green07: '#0D9E7C',
      neutral00: '#FFFFFF',
      neutral10: '#F8F8F9',
      neutral20: '#F3F3F4',
      neutral30: '#EFEFF0',
      neutral40: '#E7E8E9',
      neutral50: '#D9DBDE',
      neutral60: '#B5BABF',
      neutral70: '#84898E',
      neutral75: '#6C7079',
      neutral80: '#464C53',
      neutral90: '#2C3239',
      neutral100: '#171C22',
      primary10: '#ECF8FA',
      primary20: '#D8F1F5',
      primary40: '#8BCAD6',
      primary50: '#03A3B8',
      primary60: '#00889A',
      primary70: '#006E7B',
      success10: '#DFF4ED',
      success20: '#BDE5D8',
      success40: '#57B091',
      success50: '#019964',
      success60: '#0C875C',
      success70: '#0E7450',
      warning10: '#FAF0E8',
      warning20: '#F5DCCD',
      warning40: '#E3966D',
      warning50: '#EB7535',
      warning60: '#D86324',
      warning70: '#C34D0D',
      danger20: '#EFD0D5',
      danger10: '#FAEAEF',
      danger40: '#E4808D',
      danger50: '#D64148',
      danger60: '#C92E3D',
      purple01: '#F9F0FF',
      purple03: '#D3ADF7',
      purple06: '#722ED1',
      geekblue01: '#F0F5FF',
      geekblue03: '#ADC6FF',
      geekblue06: '#2F54EB',
      blue02: '#E6F7FF',
      blue03: '#91D5FF',
      blue05: '#1890FF',
      blue06: '#1677FF',
      red05: '#FF4D4F',
      orange06: '#FA8C16',
    },
    support: {
      phone: '8 800 444 04 32',
      email: 'apex@autoprologistic.ru',
    },
    links: {
      termsOfUse: 'docs/apl/terms.html',
      privacyPolicies: 'docs/apl/privacy.html',
    },
    isInformationPanelEnabled: false,
  },
  default: {
    ant: {
      primaryColor: '#0066FF',
      warningColor: '#FAAD14',
    },
    icons: {
      logo: 'trucker-tms-dark',
      favicon: 'favicon.svg',
      faviconNotification: 'favicon-notification.svg',
    },
    variables: '_variables_default.scss',
    welcomeLoaderProgress: {
      start: '#096dd9',
      end: '#91d5ff',
    },
    jsColors: {
      green01: '#F6FFED',
      green03: '#B7EB8F',
      green06: '#52C41A',
      green07: '#389E0D',
      neutral00: '#FFFFFF',
      neutral10: '#F8F8F9',
      neutral20: '#F3F3F4',
      neutral30: '#EFEFF0',
      neutral40: '#E7E8E9',
      neutral50: '#D9DBDE',
      neutral60: '#B5BABF',
      neutral70: '#84898E',
      neutral75: '#6C7079',
      neutral80: '#464C53',
      neutral90: '#2C3239',
      neutral100: '#171C22',
      primary10: '#E6F0FF',
      primary20: '#CCE0FF',
      primary40: '#6FA8FF',
      primary50: '#0066FF',
      primary60: '#095DDB',
      primary70: '#004ABE',
      success10: '#DFF4ED',
      success20: '#BDE5D8',
      success40: '#57B091',
      success50: '#019964',
      success60: '#0C875C',
      success70: '#0E7450',
      warning10: '#FAF0E8',
      warning20: '#F5DCCD',
      warning40: '#E3966D',
      warning50: '#EB7535',
      warning60: '#D86324',
      warning70: '#C34D0D',
      danger20: '#EFD0D5',
      danger10: '#FAEAEF',
      danger40: '#E4808D',
      danger50: '#D64148',
      danger60: '#C92E3D',
      purple01: '#F9F0FF',
      purple03: '#D3ADF7',
      purple06: '#722ED1',
      geekblue01: '#F0F5FF',
      geekblue03: '#ADC6FF',
      geekblue04: '#85A5FF',
      geekblue06: '#2F54EB',
      blue02: '#E6F7FF',
      blue03: '#91D5FF',
      blue05: '#1890FF',
      blue06: '#1677FF',
      red05: '#FF4D4F',
      orange06: '#FA8C16',
    },
    support: {
      phone: '8 800 444 04 32',
      email: 'help@trucker.group',
    },
    links: {
      termsOfUse: '/docs/default/terms.pdf',
      privacyPolicies: '/docs/default/privacy.pdf',
    },
    isInformationPanelEnabled: true,
  },
};
