<template>
  <div
    class="date-picker-input-section"
    :class="{ 'date-picker-input-section_selected': isSelected }"
    @mousedown="$emit('select')"
  >
    <span v-if="value">{{ value }}</span>

    <template v-if="isInputActive && placeholderCount">
      <span
        v-for="position in placeholderCount"
        :key="position"
        :class="[
          'date-picker-input-section__placeholder',
          `date-picker-input-section__placeholder_${size}`,
        ]"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { ESize } from '@/ui/types';

export default defineComponent({
  name: 'DatePickerInputSection',
  props: {
    value: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isInputActive: {
      type: Boolean,
      default: false,
    },
    placeholderCount: {
      type: Number,
      default: 0,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
  },
  emits: ['select'],
});
</script>

<style lang="scss" src="./styles.scss" />
